import { createAction, props } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

export const OAuthActions = {
    AUTHORIZE_GOOGLE: createAction('[OAuth] Authorize Google ' + uuidv4(), 
        props<{ oAuthToken: string; email: string; firstName: string | null; lastName: string | null }>()
    ),
    AUTHORIZE_GOOGLE_SUCCESS: createAction('[OAuth] Authorize Google Success ' + uuidv4(), props<{ data: any }>()),
    AUTHORIZE_GOOGLE_FAILURE: createAction('[OAuth] Authorize Google Failure ' + uuidv4(), props<{ error: any }>()),

    AUTHORIZE_BROKER: createAction('[OAuth] Authorize Broker ' + uuidv4(), 
        props<{ requestToken: string; apiKey: string }>()
    ),
    AUTHORIZE_BROKER_SUCCESS: createAction('[OAuth] Authorize Broker Success ' + uuidv4(), props<{ data: any }>()),
    AUTHORIZE_BROKER_FAILURE: createAction('[OAuth] Authorize Broker Failure ' + uuidv4(), props<{ error: any }>()),
};
