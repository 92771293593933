<div id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none ">

<app-header [heading]="'Sign up at Quicko'" [subHeading]="'Please enter the OTP sent to'" [title]="email"></app-header>
    <div class="mt-8 flex flex-col items-center justify-center">
      <app-otp-input  [isError]="errorMessage?true:false" (onInputChange)="onOtpChange($event)" (onEnter)="isValid()?verify():showError()"></app-otp-input>
    </div>
    <p [ngClass]="errorMessage ? 'mb-2' : 'mb-6'"
      class=" mat-label-large text-error ml-4 justify-center items-center text-center w-full">
      {{errorMessage}}
    </p>
    <div class="h-[212px]">
      <button (click)="resendOTP()" [disabled]="timeLeft != 30"
        [ngClass]="timeLeft == 30 ? 'text-primary' : 'text-outline'"
        class="mat-body-medium">Resend
        OTP<span *ngIf="timeLeft != 30"> (Available in {{timeLeft}}s)</span></button>
    </div>

    <div class="flex flex-col gap-4 justify-center mt-6">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button mat-flat-button #blueButton
          (click)="isValid()?verify():showError()" [id]="'continueButton'">Continue</button>
      </ng-container>
      <button mat-stroked-button
        (click)="back()" [id]="'backButton'">Back</button>
    </div>


  </div>



  <ng-template #loading>
    <button-loader [width]="blueButtonWidth?.toString()||'326'"></button-loader>
  </ng-template>
</div>