import {
  GoogleSigninButtonModule,
  SocialAuthService
} from '@abacritt/angularx-social-login';
import { CommonModule, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import _ from 'lodash';

import {
  EntitlementsService,
  OAuthService,
  ReferenceDataService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import {
  OAuthActions,
  ReferenceDataSelectors,
  UserActions,
} from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENV_CONFIG, SnackbarService, User } from '@org.quicko/ngx-core';
import {
  RxFormBuilder,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import { Subject, take, takeUntil } from 'rxjs';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatButtonModule,
    RxReactiveFormsModule,
    CommonModule,
    MatLabel,
    ReactiveFormsModule,
    NgClass,
    MatHint,
    MatError,
    MatIcon,
    NgIf,
    MatOption,
    MatSelect,
    NgForOf,
    MatOption,
    MatInputModule,
    RouterModule,
    NgStyle,
    MatProgressSpinnerModule,
    GoogleSigninButtonModule,
    ButtonLoaderComponent,
    HeaderComponent,
  ],
})
export class MobileLoginComponent implements OnInit, OnChanges {
  mobileForm: FormGroup;
  countryCode: number = 91;
  countryList: any;
  selectedCountry: any;
  isUser: boolean = true;
  googleError: boolean = false;
  redirectUrl: string | undefined;
  destroy$: Subject<void> = new Subject();
  saveFormLoading: boolean = false;
  googleLogin: boolean = false;
  userError: string | undefined;
  googleButtonWidth: number;
  user:any
  countries$ = this.store.select(ReferenceDataSelectors.COUNTRIES);
  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private authService: AuthService,
    private oauthService: OAuthService,
    private snackbarService:SnackbarService,
    private socialAuthService: SocialAuthService,
    private referenceDataService: ReferenceDataService,
    private entitlementsService: EntitlementsService,
    private activatedRoute: ActivatedRoute,
    @Inject(ENV_CONFIG) private environment: any,
    private fb: RxFormBuilder,
    private sharedService: SharedService
  ) {
    this.mobileForm = this.fb.group({
      mobile: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Mobile number is required' }),
          RxwebValidators.numeric({ message: 'Invalid mobile number' }),
        ],
      ],
    });

   
  }

  ngOnChanges(): void {
    
    setTimeout(() => {
      if(!this.googleButtonWidth){
        this.googleButtonWidth =
          this.blueButton?.nativeElement?.getBoundingClientRect()?.width;
      }
    });
  }
  ngAfterViewInit(): void {

    setTimeout(() => {
      if(!this.googleButtonWidth){
        this.googleButtonWidth =
          this.blueButton?.nativeElement?.getBoundingClientRect()?.width;
      }
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.mobileForm.controls['mobile'].setValue(params['mobile']);
      if (params['countryCode']) {
        this.countryCode = parseInt(params['countryCode']);
      }
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.saveFormLoading = true;
        this.store.dispatch(
          OAuthActions.AUTHORIZE_GOOGLE({
            oAuthToken: user['idToken'],
            email: user['email'],
            firstName: user['firstName'] ? user['firstName'] : null,
            lastName: user['lastName'] ? user['lastName'] : null,
          })
        );
      }
    });

    this.actions$
      .pipe(ofType(OAuthActions.AUTHORIZE_GOOGLE_SUCCESS))
      .subscribe(({data}) => {
        this.authService.setToken(data['access_token']);

        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        } else {
          window.location.href = this.environment.income_tax_url;
        }
        this.googleLogin = false;
        this.saveFormLoading = false;
      });

    this.actions$
      .pipe(ofType(OAuthActions.AUTHORIZE_GOOGLE_FAILURE))
      .subscribe((err) => {
        if (err.error.message.includes('User is locked')) {
          this.router.navigate(['./locked']);
        }
        this.saveFormLoading = false;
        this.googleError = true;
        this.googleLogin = false;
      });

    this.countries$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.countryList = data;
      this.countryList = _.orderBy(this.countryList, ['name'], ['asc']);
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numeric_code === this.countryCode
      );
      if (!this.selectedCountry) {
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numeric_code === 91
        );
      }
    });

    this.mobileForm.controls['mobile'].valueChanges.subscribe(() => {
      this.isUser = true;
      this.googleError = false;
      this.userError = undefined;
    });
  }

  showError() {
    this.mobileForm.markAllAsTouched();
  }
  isValid() {
    return this.mobileForm.valid && this.selectedCountry;
  }

  save() {
    this.saveFormLoading = true;
    const user=new User();
    user.mobile=this.mobileForm.controls['mobile'].value
    user.country_code=this.selectedCountry.numeric_code.toString()
    this.store.dispatch(
      UserActions.GET_USER_METADATA_MOBLE({user})
    );

    this.actions$
      .pipe(
        ofType(UserActions.GET_USER_METADATA_MOBILE_SUCCESS),
        take(1)
      )
      .subscribe(({data}) => {
        this.user=data;
        this.router.navigate(['./challenge/otp'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            mobile: this.mobileForm.controls['mobile'].value,
            countryCode: this.selectedCountry.numeric_code,
            credential: this.user['credential_status'],
          },
        });
        this.saveFormLoading = false;
      });

    this.actions$
      .pipe(ofType(UserActions.GET_USER_METADATA_MOBILE_FAILURE))
      .subscribe((err) => {
        if (err.error.message.includes('User does not exist')) {
          this.isUser = false;
        } else if (
          err.error.message.includes('User is locked') ||
          err.error.message.includes('User is inactive')
        ) {
          this.userError = err.error.message;
          this.router.navigate(['locked']);
        }
        this.saveFormLoading = false;
      });

  }


  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
