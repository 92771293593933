import { Injectable } from '@angular/core';
import { EntitlementsService } from '@com.quicko/accounts-ngx-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap } from 'rxjs/operators';
import { UserActions } from './user.actions';

@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private entitlementsService: EntitlementsService) {}

  // Get User Metadata
  getUserMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GET_USER_METADATA),
      switchMap((action) =>
        this.entitlementsService.getUserMetadata(action.user).pipe(
          map(response => UserActions.GET_USER_METADATA_SUCCESS({ data: response })),
          catchError(async err => UserActions.GET_USER_METADATA_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Get User Metadata
  getUserMetadataMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GET_USER_METADATA_MOBLE),
      switchMap((action) =>
        this.entitlementsService.getUserMetadataByMobile(action.user).pipe(
          map(response => UserActions.GET_USER_METADATA_MOBILE_SUCCESS({ data: response })),
          catchError(async err => UserActions.GET_USER_METADATA_MOBILE_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Login
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LOGIN),
      switchMap((action) =>
        this.entitlementsService.login(action.user).pipe(
          map(response => UserActions.LOGIN_SUCCESS({ data: response })),
          catchError(async err => UserActions.LOGIN_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Login Mobile
  loginByMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LOGIN_MOBILE),
      switchMap((action) =>
        this.entitlementsService.loginByMobile( action.user).pipe(
          map(response => UserActions.LOGIN_MOBILE_SUCCESS({ data: response })),
          catchError(async err => UserActions.LOGIN_MOBILE_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Logout
  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.LOGOUT),
      switchMap(() =>
        this.entitlementsService.logout().pipe(
          map(() => UserActions.LOGOUT_SUCCESS()),
          catchError(async err => UserActions.LOGOUT_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Get OTP by Email
  getOTPByEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GET_OTP_BY_EMAIL),
      switchMap((action) =>
        this.entitlementsService.getOTPByEmail(action.user).pipe(
          map((data) => UserActions.GET_OTP_BY_EMAIL_SUCCESS({data:data})),
          catchError(async err => UserActions.GET_OTP_BY_EMAIL_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Get OTP by Mobile
  getOTPByMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.GET_OTP_BY_MOBILE),
      switchMap((action) =>
        this.entitlementsService.getOTPByMobile(action.user).pipe(
          map((data) => UserActions.GET_OTP_BY_MOBILE_SUCCESS({data:data})),
          catchError(async err => UserActions.GET_OTP_BY_MOBILE_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Verify OTP by Email
  verifyEmailOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.VERIFY_EMAIL_OTP),
      switchMap((action) =>
        this.entitlementsService.verifyEmailOTP(action.user, action.otp).pipe(
          map((data) => UserActions.VERIFY_EMAIL_OTP_SUCCESS({data:data})),
          catchError(async err => UserActions.VERIFY_EMAIL_OTP_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Verify OTP by Mobile
  verifyMobileOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.VERIFY_MOBILE_OTP),
      switchMap((action) =>
        this.entitlementsService.verifyMobileOTP(action.user, action.otp).pipe(
          map((data) => UserActions.VERIFY_MOBILE_OTP_SUCCESS({data:data})),
          catchError(async err => UserActions.VERIFY_MOBILE_OTP_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Signup
  signup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SIGNUP),
      switchMap((action) =>
        this.entitlementsService.signup(action.user).pipe(
          map(response => UserActions.SIGNUP_SUCCESS({ data: response })),
          catchError(async err => UserActions.SIGNUP_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Signup OTP Verification
  signupOTPVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SIGNUP_EMAIL_OTP),
      switchMap((action) =>
        this.entitlementsService.signupOTPVerify(action.user, action.otp).pipe(
          map(response => UserActions.SIGNUP_EMAIL_OTP_SUCCESS({ data: response })),
          catchError(async err => UserActions.SIGNUP_EMAIL_OTP_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Signup Link Mobile 
  signupMobileOTPVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SIGNUP_LINK_MOBILE),
      switchMap((action) =>
        this.entitlementsService.getOTPByNewMobile(action.user).pipe(
          map(response => UserActions.SIGNUP_LINK_MOBILE_SUCCESS({ data: response })),
          catchError(async err => UserActions.SIGNUP_LINK_MOBILE_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Signup Link Mobile Verify OTP
  signupLinkMobileOTPVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SIGNUP_LINK_MOBILE_VERIFY),
      switchMap((action) =>
        this.entitlementsService.verifyNewMobileOTP(action.user,action.otp).pipe(
          map(response => UserActions.SIGNUP_LINK_MOBILE_VERIFY_SUCCESS({ data: response })),
          catchError(async err => UserActions.SIGNUP_LINK_MOBILE_VERIFY_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Set Password
  setPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.SET_PASSWORD),
      switchMap((action) =>
        this.entitlementsService.setPassword(action.user).pipe(
          map(response => UserActions.SET_PASSWORD_SUCCESS({ data: response })),
          catchError(async err => UserActions.SET_PASSWORD_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Reset Password by Email
  resetPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.RESET_PASSWORD_EMAIL),
      switchMap((action) =>
        this.entitlementsService.resetPasswordEmail(action.user).pipe(
          map(response => UserActions.RESET_PASSWORD_EMAIL_SUCCESS({ data: response })),
          catchError(async err => UserActions.RESET_PASSWORD_EMAIL_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Reset Password by Mobile
  resetPasswordMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.RESET_PASSWORD_MOBILE),
      switchMap((action) =>
        this.entitlementsService.resetPasswordMobile(action.user).pipe(
          map(response => UserActions.RESET_PASSWORD_MOBILE_SUCCESS({ data: response })),
          catchError(async err => UserActions.RESET_PASSWORD_MOBILE_FAILURE({ error:err.error }))
        )
      )
    )
  );

  // Verify Reset Password by Email
  verifyResetPasswordEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.VERIFY_RESET_PASSWORD_EMAIL),
      switchMap((action) =>
        this.entitlementsService.verifyResetPasswordEmail(action.user, action.otp).pipe(
          map(response => UserActions.VERIFY_RESET_PASSWORD_EMAIL_SUCCESS({ data: response })),
          catchError(async err => UserActions.VERIFY_RESET_PASSWORD_EMAIL_FAILURE({ error:err.error }))
        )
      )
    )
  );
  // Verify Reset Password by Mobile
  verifyResetPasswordMobile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.VERIFY_RESET_PASSWORD_MOBILE),
      switchMap((action) =>
        this.entitlementsService.verifyResetPasswordMobile(action.user,action.otp).pipe(
          map(response => UserActions.VERIFY_RESET_PASSWORD_MOBILE_SUCCESS({ data: response })),
          catchError(async err => UserActions.VERIFY_RESET_PASSWORD_MOBILE_FAILURE({ error:err.error }))
        )
      )
    )
  );
}
