import { createAction, props } from '@ngrx/store';
import { User, UserDetails } from '@org.quicko/ngx-core';
import { v4 as uuidv4 } from 'uuid';

export const UserActions = {
    GET_USER_METADATA: createAction('[User] Get Metadata ' + uuidv4(), props<{ user:User }>()),
    GET_USER_METADATA_SUCCESS: createAction('[User] Get Metadata Success ' + uuidv4(), props<{ data: any }>()),
    GET_USER_METADATA_FAILURE: createAction('[User] Get Metadata Failure ' + uuidv4(), props<{ error: any }>()),

    GET_USER_METADATA_MOBLE: createAction('[User] Get Metadata  Mobile' + uuidv4(), props<{ user:User }>()),
    GET_USER_METADATA_MOBILE_SUCCESS: createAction('[User] Get Metadata Mobile Success ' + uuidv4(), props<{ data: any }>()),
    GET_USER_METADATA_MOBILE_FAILURE: createAction('[User] Get Metadata Mobile Failure ' + uuidv4(), props<{ error: any }>()),

    LOGIN: createAction('[User] Login ' + uuidv4(), props<{ user:User }>()),
    LOGIN_SUCCESS: createAction('[User] Login Success ' + uuidv4(), props<{ data: any }>()),
    LOGIN_FAILURE: createAction('[User] Login Failure ' + uuidv4(), props<{ error: any }>()),
    
    LOGIN_MOBILE: createAction('[User] Login ' + uuidv4(), props<{ user:User }>()),
    LOGIN_MOBILE_SUCCESS: createAction('[User] Login Success ' + uuidv4(), props<{ data: any }>()),
    LOGIN_MOBILE_FAILURE: createAction('[User] Login Failure ' + uuidv4(), props<{ error: any }>()),

    LOGOUT: createAction('[User] Logout ' + uuidv4()),
    LOGOUT_SUCCESS: createAction('[User] Logout Success ' + uuidv4()),
    LOGOUT_FAILURE: createAction('[User] Logout Failure ' + uuidv4(), props<{ error: any }>()),

    GET_OTP_BY_EMAIL: createAction('[User] Get OTP By Email ' + uuidv4(), props<{ user:User }>()),
    GET_OTP_BY_EMAIL_SUCCESS: createAction('[User] Get OTP By Email Success ' + uuidv4(), props<{ data:any }>()),
    GET_OTP_BY_EMAIL_FAILURE: createAction('[User] Get OTP By Email Failure' + uuidv4(), props<{ error:any }>()),
    
    GET_OTP_BY_MOBILE: createAction('[User] Get OTP By Mobile ' + uuidv4(), props<{ user:User }>()),
    GET_OTP_BY_MOBILE_SUCCESS: createAction('[User] Get OTP By Mobile Success ' + uuidv4(), props<{ data:any }>()),
    GET_OTP_BY_MOBILE_FAILURE: createAction('[User] Get OTP By Mobile Failure' + uuidv4(), props<{ error:any }>()),
    
    VERIFY_EMAIL_OTP: createAction('[User] Verify Email OTP ' + uuidv4(), props<{ user:User; otp: string }>()),
    VERIFY_EMAIL_OTP_SUCCESS: createAction('[User] Verify Email OTP Success ' + uuidv4(), props<{ data:any }>()),
    VERIFY_EMAIL_OTP_FAILURE: createAction('[User] Verify Email OTP Failure' + uuidv4(), props<{ error:any }>()),
    
    VERIFY_MOBILE_OTP: createAction('[User] Verify Mobile OTP ' + uuidv4(), props<{ user:User, otp: string }>()),
    VERIFY_MOBILE_OTP_SUCCESS: createAction('[User] Verify Mobile OTP Success ' + uuidv4(), props<{ data:any }>()),
    VERIFY_MOBILE_OTP_FAILURE: createAction('[User] Verify Mobile OTP Failure' + uuidv4(), props<{ error:any }>()),

    SIGNUP: createAction('[User] Signup ' + uuidv4(), props<{ user:UserDetails; }>()),
    SIGNUP_SUCCESS: createAction('[User] Signup Success ' + uuidv4(), props<{ data: any }>()),
    SIGNUP_FAILURE: createAction('[User] Signup Failure ' + uuidv4(), props<{ error: any }>()),
    
    SIGNUP_EMAIL_OTP: createAction('[User] Signup Email OTP ' + uuidv4(), props<{ user:User; otp:string }>()),
    SIGNUP_EMAIL_OTP_SUCCESS: createAction('[User] Signup Email OTP Success ' + uuidv4(), props<{ data: any }>()),
    SIGNUP_EMAIL_OTP_FAILURE: createAction('[User] Signup Email OTP Failure ' + uuidv4(), props<{ error: any }>()),
    
    SIGNUP_LINK_MOBILE: createAction('[User] Signup Link Mobile ' + uuidv4(), props<{user:User }>()),
    SIGNUP_LINK_MOBILE_SUCCESS: createAction('[User] Signup Link Mobile Success ' + uuidv4(), props<{ data: any }>()),
    SIGNUP_LINK_MOBILE_FAILURE: createAction('[User] Signup Link Mobile Failure ' + uuidv4(), props<{ error: any }>()),
    
    SIGNUP_LINK_MOBILE_VERIFY: createAction('[User] Signup Link Mobile  Verify' + uuidv4(), props<{user:User,otp:string }>()),
    SIGNUP_LINK_MOBILE_VERIFY_SUCCESS: createAction('[User] Signup Link Mobile Verify Success ' + uuidv4(), props<{ data: any }>()),
    SIGNUP_LINK_MOBILE_VERIFY_FAILURE: createAction('[User] Signup Link Mobile Verify Failure ' + uuidv4(), props<{ error: any }>()),

    SET_PASSWORD: createAction('[User] Set Password ' + uuidv4(), props<{ user:User }>()),
    SET_PASSWORD_SUCCESS: createAction('[User] Set Password Success ' + uuidv4(), props<{ data: any }>()),
    SET_PASSWORD_FAILURE: createAction('[User] Set Password Failure ' + uuidv4(), props<{ error: any }>()),

    RESET_PASSWORD_EMAIL: createAction('[User] Reset Password By Email ' + uuidv4(), props<{ user:User }>()),
    RESET_PASSWORD_EMAIL_SUCCESS: createAction('[User] Reset Password By Email Success ' + uuidv4(), props<{ data: any }>()),
    RESET_PASSWORD_EMAIL_FAILURE: createAction('[User] Reset Password By Email Failure ' + uuidv4(), props<{ error: any }>()),
   
    RESET_PASSWORD_MOBILE: createAction('[User] Reset Password By Mobile ' + uuidv4(), props<{ user:User }>()),
    RESET_PASSWORD_MOBILE_SUCCESS: createAction('[User] Reset Password By Mobile Success ' + uuidv4(), props<{ data: any }>()),
    RESET_PASSWORD_MOBILE_FAILURE: createAction('[User] Reset Password By Mobile Failure ' + uuidv4(), props<{ error: any }>()),

    VERIFY_RESET_PASSWORD_EMAIL: createAction('[User] Verify Reset Password Email ' + uuidv4(), props<{ user:User; otp: string }>()),
    VERIFY_RESET_PASSWORD_EMAIL_SUCCESS: createAction('[User] Verify Reset Password Email Success ' + uuidv4(), props<{ data: any }>()),
    VERIFY_RESET_PASSWORD_EMAIL_FAILURE: createAction('[User] Verify Reset Password Email Failure ' + uuidv4(), props<{ error: any }>()),

    VERIFY_RESET_PASSWORD_MOBILE: createAction('[User] Verify Reset Password Email ' + uuidv4(), props<{ user:User, otp: string }>()),
    VERIFY_RESET_PASSWORD_MOBILE_SUCCESS: createAction('[User] Verify Reset Password Email Success ' + uuidv4(), props<{ data: any }>()),
    VERIFY_RESET_PASSWORD_MOBILE_FAILURE: createAction('[User] Verify Reset Password Email Failure ' + uuidv4(), props<{ error: any }>()),
};
