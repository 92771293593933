import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from '@com.quicko/ngx-core';


@Injectable({ providedIn: 'root' })
export class AffiliateService {
  constructor(private cookieService: CookieService) {
  }

  public setCookie(affiliateId: string): void {
    if (!this.cookieService.get('AFFILIATEID')) {
      this.cookieService.setCookie('AFFILIATEID', affiliateId, 30);
    }
  }

  public getCookie(): string {
    return this.cookieService.get('AFFILIATEID');
  }

  public getAffiliateParams(params?: HttpParams) {
    if (params == null) {
      params = new HttpParams;
    }

    if (this.getCookie()) {
      params = params.append('affiliate_id', this.getCookie());
    }

    if (this.cookieService.getCookie('UTM_SOURCE')) {
      params = params.append('utm_source', this.cookieService.getCookie('UTM_SOURCE'));
    }

    if (this.cookieService.getCookie('UTM_MEDIUM')) {
      params = params.append('utm_medium', this.cookieService.getCookie('UTM_MEDIUM'));
    }

    if (this.cookieService.getCookie('UTM_CAMPAIGN')) {
      params = params.append('utm_campaign', this.cookieService.getCookie('UTM_CAMPAIGN'));
    }

    if (this.cookieService.getCookie('UTM_ID')) {
      params = params.append('utm_id', this.cookieService.getCookie('UTM_ID'));
    }

    if (this.cookieService.getCookie('UTM_TERM')) {
      params = params.append('utm_term', this.cookieService.getCookie('UTM_TERM'));
    }

    if (this.cookieService.getCookie('UTM_CONTENT')) {
      params = params.append('utm_content', this.cookieService.getCookie('UTM_CONTENT'));
    }

    return params;
  }

}
