import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';

import { ENV_CONFIG, SnackbarService, User } from '@org.quicko/ngx-core';
import {
  RxFormBuilder,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';

import {
  EntitlementsService,
  ReferenceDataService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import {
  ReferenceDataSelectors,
  UserActions,
} from '@com.quicko/accounts-ngx-redux-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, take, takeUntil } from 'rxjs';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';

@Component({
  selector: 'app-link-mobile',
  templateUrl: './link-mobile.component.html',
  standalone: true,
  imports: [
    MatFormField,
    RxReactiveFormsModule,
    MatLabel,
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule,
    NgClass,
    MatHint,
    MatError,
    MatIcon,
    NgIf,
    SocialLoginModule,
    MatInputModule,
    MatOption,
    MatSelect,
    NgForOf,
    NgStyle,
    MatProgressSpinnerModule,
    ButtonLoaderComponent,
    HeaderComponent,
  ],
})
export class LinkMobileComponent implements OnInit {
  email!: string;
  name!: string;
  mobileForm: FormGroup;
  countryCode: number = 91;
  countryList: any;
  countries$ = this.store.select(ReferenceDataSelectors.COUNTRIES);
  selectedCountry: any;
  isUser: boolean = false;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  blueButtonWidth: number;

  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  destroy$ = new Subject<void>();
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private referenceDataService: ReferenceDataService,
    private entitlementsService: EntitlementsService,
    private store: Store,

    private actions$: Actions,
    private snackbarService: SnackbarService,

    @Inject(ENV_CONFIG) private environment: any,
    private fb: RxFormBuilder,
    private sharedService: SharedService
  ) {
    this.mobileForm = this.fb.group({
      mobile: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Mobile number is required' }),
          RxwebValidators.numeric({ message: 'Invalid mobile number' }),
          RxwebValidators.maxLength({
            value: 15,
            message: 'Invalid mobile number',
          }),
        ],
      ],
    });
   
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.name = params['name'];
      this.mobileForm.controls['mobile'].setValue(params['mobile']);
      if (params['countryCode']) {
        this.countryCode = parseInt(params['countryCode']);
      }
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });
    this.countries$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.countryList = response
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numeric_code === this.countryCode
      );
      if (!this.selectedCountry) {
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numeric_code === 91
        );
      }
    });

    this.mobileForm.controls['mobile'].valueChanges.subscribe(() => {
      this.isUser = false;
    });
  }

  showError() {
    this.mobileForm.markAllAsTouched();
  }
  isValid() {
    return this.mobileForm.valid;
  }
  ngOnChanges(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.blueButtonWidth =
        this.blueButton.nativeElement.getBoundingClientRect().width;
    });
  }
  save() {
    if (this.mobileForm.valid && this.selectedCountry) {
      this.saveFormLoading = true;
      const user= new User()
      user.username= this.email,
      user.mobile=this.mobileForm.controls['mobile'].value,
      user.country_code=this.selectedCountry.numeric_code,
      this.store.dispatch(
        UserActions.SIGNUP_LINK_MOBILE({
         user
        })
      );

      this.actions$
        .pipe(ofType(UserActions.SIGNUP_LINK_MOBILE_SUCCESS), take(1))
        .subscribe(() => {
          this.router.navigate(['verify'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              email: this.email,
              name: this.name,
              mobile: this.mobileForm.controls['mobile'].value,
              countryCode: this.selectedCountry.numeric_code,
            },
          });
          this.saveFormLoading = false;
        });
      this.actions$
        .pipe(ofType(UserActions.SIGNUP_LINK_MOBILE_FAILURE), take(1))
        .subscribe((err) => {
          if (
            err.error.message.includes('User already exist') ||
            err.error.message.includes('User is locked')
          ) {
            this.isUser = true;
          } else if(err.error.message.includes("Too many OTP generation attempts")){
            this.router.navigate(['verify'], {
              relativeTo: this.activatedRoute,
              queryParams: {
                email: this.email,
                name: this.name,
                mobile: this.mobileForm.controls['mobile'].value,
                countryCode: this.selectedCountry.numeric_code,
              },
            });
          }
          else {
            this.snackbarService.openSnackBar("Failed to link mobile",null);
          }
          this.saveFormLoading = false;
        });
    } else {
      this.mobileForm.controls['mobile'].markAsTouched();
    }
  }

  skip() {
    if (this.redirectUrl) {
      window.location.href = window.atob(this.redirectUrl);
    } else {
      window.location.href = this.environment.income_tax_url;
    }
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
