import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ENV_CONFIG } from "@org.quicko/ngx-core";
import { Observable } from "rxjs";
import { EntitlementsService } from "../services";
import { AuthService } from "@com.quicko/ngx-core";

@Injectable()
export class LoggedIn implements CanActivate {
  authentication_host: string;
  constructor( @Inject(ENV_CONFIG) private environment:any,private authService: AuthService, private router: Router, private entitlementsService: EntitlementsService) {
    if (environment.production) {
      this.authentication_host = 'https://accounts.quicko.com/';
    } else {
      this.authentication_host = 'http://localhost:4200/';
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}