import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import {
  MatError,
  MatFormField,
  MatHint,
  MatInputModule,
  MatLabel,
} from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';

import {
  EntitlementsService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENV_CONFIG, SnackbarService, User } from '@org.quicko/ngx-core';
import {
  RxFormBuilder,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';

@Component({
  selector: 'app-password-login',
  templateUrl: './password-login.component.html',
  standalone: true,
  imports: [
    MatFormField,
    CommonModule,
    RxReactiveFormsModule,
    MatButtonModule,
    MatLabel,
    ReactiveFormsModule,
    NgClass,
    MatHint,
    MatError,
    MatIcon,
    NgIf,
    SocialLoginModule,
    MatInputModule,
    NgStyle,
    ButtonLoaderComponent,
    HeaderComponent,
  ],
})
export class PasswordLoginComponent implements OnInit {
  email!: string;
  mobile: string | undefined;
  countryCode: string | undefined;
  credential!: string;
  passwordForm: FormGroup;
  showPassword: boolean = false;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  incorrectPassword: boolean = false;
  blueButtonWidth: number;
destroy$ = new Subject<void>();
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private store: Store,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private authService: AuthService,
    private fb: RxFormBuilder,
    private sharedService: SharedService,
    @Inject(ENV_CONFIG) private environment: any
  ) {
    this.passwordForm = this.fb.group({
      password: [
        '',
        [RxwebValidators.notEmpty({ message: 'Password is required' })],
      ],
    });

    
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.mobile = params['mobile'];
      this.credential = params['credential'];
      this.countryCode = params['countryCode'];
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });
    this.passwordForm.controls['password'].valueChanges.subscribe(() => {
      this.incorrectPassword = false;
    });
  }
  ngOnChanges(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.blueButtonWidth =
        this.blueButton.nativeElement.getBoundingClientRect().width;
    });
  }

  verify() {
    if (this.email) {
      this.saveFormLoading = true;
      const user=new User()
      user.username=this.email
      user.password=this.passwordForm.controls['password'].value
      this.store.dispatch(
        UserActions.LOGIN({
          user,
        })
      );
      this.actions$
        .pipe(ofType(UserActions.LOGIN_SUCCESS), take(1))
        .subscribe(({data}) => {
          this.authService.setToken(data['access_token']);

          if (this.redirectUrl) {
            window.location.href = window.atob(this.redirectUrl);
          } else {
            window.location.href = this.environment.income_tax_url;
          }
          this.saveFormLoading = false;
        });
      this.actions$
        .pipe(ofType(UserActions.LOGIN_FAILURE), take(1))
        .subscribe((err) => {
          if (err.error.message) {
            if (err.error.message.includes('Bad credentials')) {
              this.incorrectPassword = true;
            } else {
              this.router.navigate(['login']);
            }
          }
          this.saveFormLoading = false;
        });
    } else if (this.mobile && this.countryCode) {
      const user=new User()
      user.mobile=this.mobile
        user.country_code=this.countryCode
        user.password=this.passwordForm.controls['password'].value
      this.store.dispatch(UserActions.LOGIN_MOBILE({
        user
      }))

      this.actions$.pipe(ofType(UserActions.LOGIN_MOBILE_SUCCESS),take(1)).subscribe(({data})=>{
        this.authService.setToken(data['access_token']);
            if (this.redirectUrl) {
              window.location.href = window.atob(this.redirectUrl);
            } else {
              window.location.href = this.environment.income_tax_url;
            }
            this.saveFormLoading = false;
      })

      this.actions$
        .pipe(ofType(UserActions.LOGIN_MOBILE_FAILURE), take(1))
        .subscribe((err) => {
          if (err.error.message) {
            if (err.error.message.includes('Bad credentials')) {
              this.incorrectPassword = true;
            } else {
              this.router.navigate(['login']);
            }
          }
          this.saveFormLoading = false;
        });

    }
  }

  showError() {
    this.passwordForm.markAllAsTouched();
  }
  isValid() {
    return this.passwordForm.valid;
  }

  navigateToOTP() {
    this.router.navigate(['../otp'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        credential: this.credential,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

  navigateToForgotPassword() {
    this.router.navigate(['/set-password/verify'], {
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

  back() {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
