<div id="password-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">

    <app-header [heading]="'Login to Quicko'" [subHeading]="'Please enter your password'" [title]="email?email: '+'+ countryCode+' '+mobile "></app-header>

    <form [formGroup]="passwordForm" (keyup.enter)="isValid()?verify():showError()" class="mt-8">
      <mat-form-field appearance="outline" class="w-full">
        <mat-label >Password</mat-label>
        <input required matInput formControlName="password" [type]="showPassword ? 'text' : 'password'" onpaste="return false"
          ondrop="return false" autocomplete="off" class="">
        <mat-hint *ngIf="incorrectPassword" class="text-error">Incorrect Password</mat-hint>
        <mat-error>
          <ng-container *ngIf="
          passwordForm.controls['password'].errors &&
          (passwordForm.controls['password'].dirty || passwordForm.controls['password'].touched)
        ">
              <ng-container *ngFor="let error of passwordForm.controls['password'].errors | keyvalue">
                  <ng-container *ngIf="error.key">
                      <span>{{ error.value['message'] }}</span>
                  </ng-container>
              </ng-container>
          </ng-container>
      </mat-error>
        <mat-icon matSuffix class="material-icons-outlined " (click)="showPassword=true"
          *ngIf="!showPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-icons-outlined " (click)="showPassword=false"
          *ngIf="showPassword">visibility_off</mat-icon>
      </mat-form-field>
    </form>

    <div class="flex flex-col items-start mb-10">
      <button (click)="navigateToForgotPassword()" class="text-primary mat-label-large mt-1">Forgot
        Password?</button>
      <button (click)="navigateToOTP()" class=" text-primary mat-label-large mt-6">Login with OTP</button>
    </div>
    <div class="flex flex-col gap-4 justify-center mt-24">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button mat-flat-button #blueButton (click)="verify()" [id]="'logInButton'">Log In</button>
      </ng-container>
      <button mat-stroked-button
        (click)="back()" [id]="'backButton'">Back</button>
    </div>

  </div>


  <ng-template #loading>
  <button-loader [width]="blueButtonWidth?.toString()||'326'"></button-loader>
  </ng-template>
</div>