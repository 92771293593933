import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG } from '@org.quicko/ngx-core';
import { AffiliateService } from './affiliate.service';


@Injectable({
  providedIn: 'root',
})
export class OAuthService {
  private authServiceEndpoint: any;
  private httpClient: HttpClient;

  constructor( @Inject(ENV_CONFIG) private environment:any,
    private affiliateService: AffiliateService,
    private handler: HttpBackend,
  ) {
    this.httpClient = new HttpClient(handler);
    this.authServiceEndpoint = environment.auth_services_endpoint;
  }

  authorizeGoogle(oAuthToken: string, email: string, firstName: string | null, lastName: string | null) {

    let headers = new HttpHeaders().set('authorization', oAuthToken);

    let params = new HttpParams();
    if (email != null) {
      params = params.set('email', email);
    }
    if (firstName != null) {
      params = params.set('first_name', firstName);
    }
    if (lastName != null) {
      params = params.set('last_name', lastName);
    }

    params = this.affiliateService.getAffiliateParams(params);

    const url = this.authServiceEndpoint + '/oauth/google/authorize';
    return this.httpClient.post(url, null, {
      params: params,
      headers: headers,
    });
  }

  authorizeBroker(requestToken: string, apiKey: string) {
    const url = this.authServiceEndpoint + '/oauth/' + apiKey + '/authorize';

    let params: HttpParams = this.affiliateService.getAffiliateParams();
    
    let headers = new HttpHeaders().set('Authorization', requestToken);

    return this.httpClient.post(url, null, {
      headers: headers,
      params: params
    });
  }
}
