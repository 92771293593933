<div id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">
    <app-header [heading]="'Login to Quicko'" [subHeading]="'Please enter OTP sent to'" [title]="email?email: '+'+ countryCode+' '+mobile "></app-header>

    <div class="mt-8 flex flex-col items-center justify-center">
      <app-otp-input  [isError]="errorMessage?true:false" (onInputChange)="onOtpChange($event)" (onEnter)="isValid()?verify():showError()"></app-otp-input>
    </div>
    <p [ngClass]="errorMessage ? 'mb-2' : 'mb-6'"
      class="mat-label-large text-center text-error mat-label-medium mt-2">
      {{errorMessage}}
    </p>

    <div class="flex flex-col items-start h-[72px] mb-8">
      <button (click)="resendOTP()" [disabled]="timeLeft != 30"
        [ngClass]="timeLeft == 30 ? 'text-primary' : 'text-outline'" class="mat-label-large">Resend
        OTP<span *ngIf="timeLeft != 30"> (Available in {{timeLeft}}s)</span></button>
      <button *ngIf="credential=='active'" (click)="navigateToPassword()"
        class="text-primary mat-label-large mt-6">Login
        with
        Password</button>
    </div>

    <div class="flex flex-col gap-4 justify-center mt-24">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button #blueButton mat-flat-button (click)="isValid()?verify():showError()" [id]="'logInButton'">Log
          In</button>
      </ng-container>
      <button mat-stroked-button
        (click)="back()" [id]="'backButton'">Back</button>
    </div>

  </div>


  <ng-template #loading>
    <button-loader [width]=" blueButtonWidth?.toString()|| '326'"></button-loader>
  </ng-template>
</div>