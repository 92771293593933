import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { EmailLoginComponent } from './email-login/email-login.component';
import { MobileLoginComponent } from './mobile-login/mobile-login.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [EmailLoginComponent, MobileLoginComponent, NgIf]
})
export class LoginComponent implements OnInit {
  @Input() useEmail: boolean = true;

  constructor(private activatedRoute: ActivatedRoute) {
  }
destroy$ = new Subject<void>();
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.useEmail = ((params['email'] || params['email'] === undefined) && !params['mobile']) ? true : false;
    });
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
