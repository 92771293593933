import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { APIResponse, ENV_CONFIG } from '@org.quicko/ngx-core';

@Injectable({providedIn: 'root'})
export class ReferenceDataService {

  private comQuickoCmsEndpoint;
  private orgQuickoCmsEndpoint;

  constructor( @Inject(ENV_CONFIG) private environment:any,private httpClient: HttpClient) {
    this.comQuickoCmsEndpoint = environment.com_quicko_cms_endpoint;
    this.orgQuickoCmsEndpoint = environment.org_quicko_cms_endpoint;
  }

  getCountries() {
    
    const url = this.orgQuickoCmsEndpoint + '/api/org-quicko-country-flags?populate=*';
    return this.httpClient.get<APIResponse<any>>(url);
  }

  getApps() {
    const url = this.comQuickoCmsEndpoint + '/api/com-quicko-user-apps?populate=*';
    return this.httpClient.get<APIResponse<any>>(url);
  }

}
