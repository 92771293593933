import { createReducer, on } from "@ngrx/store";
import { ReferenceDataActions } from "./reference-data.actions";
import { ReferenceDataStore } from "./reference-data.model";

const initialState = undefined

const onGetCountriesSuccess = on(ReferenceDataActions.GET_COUNTRIES_SUCCESS, (state: ReferenceDataStore, { countries }) => {
    return {
        ...state,
         countries: countries
         }
});
const onGetAppsSuccess = on(ReferenceDataActions.GET_APPS_SUCCESS, (state: ReferenceDataStore, { apps }) => {
    return { 
        ...state,
        apps: apps
     }
});


export const ReferenceDataReducer = createReducer(
    initialState,
    onGetCountriesSuccess,
    onGetAppsSuccess
);