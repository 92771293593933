import { CommonModule, NgFor, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import {
  EntitlementsService,
  ReferenceDataService,
} from '@com.quicko/accounts-ngx-core';
import {
  ReferenceDataSelectors,
  UserActions,
} from '@com.quicko/accounts-ngx-redux-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService, User } from '@org.quicko/ngx-core';
import { RxFormBuilder, RxReactiveFormsModule, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from '../../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../../common/header/header.component';

@Component({
  selector: 'app-mobile-recovery',
  templateUrl: './mobile-recovery.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormField,
    MatInputModule,
   
    NgFor,
    NgIf,
    MatOptionModule,
    RxReactiveFormsModule,
    MatSelectModule,
    RouterModule,

    MatProgressSpinnerModule,
    ButtonLoaderComponent,
    HeaderComponent,
  ],
})
export class MobileRecoveryComponent implements OnInit {
  mobileForm: FormGroup;
  countryCode: number = 91;
  countryList: any;
  selectedCountry: any;
  isUser: boolean = true;
  countries$ = this.store.select(ReferenceDataSelectors.COUNTRIES);
  saveFormLoading: boolean = false;
destroy$ = new Subject<void>();
  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  blueButtonWidth: number;
  constructor(
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private fb:RxFormBuilder,
    private referenceDataService: ReferenceDataService,
    private entitlementsService: EntitlementsService,
    private activatedRoute: ActivatedRoute
  ) {
    this.mobileForm = this.fb.group({
      mobile: ['', [RxwebValidators.notEmpty({message:"Mobile number is required"})]],
    });
   
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.mobileForm.controls['mobile'].setValue(params['mobile']);
      if (params['countryCode']) {
        this.countryCode = parseInt(params['countryCode']);
      }
    });
    this.countries$.subscribe((response) => {
      this.countryList = response
      this.selectedCountry = this.countryList.find(
        (c: any) => c.numeric_code === this.countryCode
      );
      if (!this.selectedCountry) {
        this.selectedCountry = this.countryList.find(
          (c: any) => c.numeric_code === 91
        );
      }
    });
    this.mobileForm.controls['mobile'].valueChanges.subscribe(() => {
      this.isUser = true;
    });
  }

  ngOnChanges(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }

  save() {
    if (this.mobileForm.valid && this.selectedCountry) {
      this.saveFormLoading = true;
      const user=new User()
      user.mobile= this.mobileForm.controls['mobile'].value,
      user.country_code= this.selectedCountry.numeric_code.toString(),
      this.store.dispatch(
        UserActions.GET_USER_METADATA_MOBLE({
         user
        })
      );
      this.actions$
        .pipe(ofType(UserActions.GET_USER_METADATA_MOBILE_FAILURE), take(1))
        .subscribe((err) => {
          if (err.error.message.includes('User does not exist')) {
            this.isUser = false;
          }
          this.saveFormLoading = false;
        });
      this.actions$
        .pipe(ofType(UserActions.GET_USER_METADATA_MOBILE_SUCCESS), take(1))
        .subscribe(() => {
         
          this.router.navigate(['otp'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              mobile: this.mobileForm.controls['mobile'].value,
              countryCode: this.selectedCountry.numeric_code,
            },
          });
          this.saveFormLoading = false;
        });
      
    } else {
      this.mobileForm.controls['mobile'].markAsTouched();
    }
  }

  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
