import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { LoggedIn, NotLoggedIn, RequestInterceptor } from '@com.quicko/accounts-ngx-core';
import { CustomSerializer, OAuthEffects, ReferenceDataEffects, RootReducers, UserEffects } from '@com.quicko/accounts-ngx-redux-core';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { ENV_CONFIG } from '@org.quicko/ngx-core';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { InitializerService } from './services/initializer.service';
export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideRouterStore({
      serializer: CustomSerializer
  }),
  provideStore(RootReducers),
  provideEffects([
    ReferenceDataEffects,
    UserEffects,
    OAuthEffects
  ]),
  provideStoreDevtools({
    maxAge: 25,
    autoPause: true,
    name: 'Accounts Store',
}),
    NotLoggedIn,
    LoggedIn,
  {
      provide: APP_INITIALIZER,
      useFactory: (initService: InitializerService) => { return initService.run() },
      multi: true,
      deps: [InitializerService]
  },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    { provide: ENV_CONFIG, useValue: environment },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '885852212396-2v5cpj2d6pm9u6pje8l2fbe07inlbbuv.apps.googleusercontent.com'
            ),
          },
        ],
      } as SocialAuthServiceConfig
    },
  ]
};

