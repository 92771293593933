<div *ngIf="(countries$|async) else loadingMobile" class="flex flex-col pt-12 pb-8 px-4  rounded justify-between grow container border-none">

  <div class="flex flex-col gap-8">
    <app-header [heading]="'Login into Quicko'" [subHeading]="'Welcome back!'"></app-header>
    <div class="flex flex-col gap-3"> 
      <form class="flex flex-col" [formGroup]="mobileForm" (keyup.enter)="isValid()?save():showError()">
        <mat-form-field appearance="outline" class="w-full">
          <img matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-3" />
          <mat-select [(value)]="selectedCountry">
            <mat-option *ngFor="let c of countryList" [value]="c">
              <div class="flex flex-row">
                <img src="{{c.flag}}" class="h-5 w-7 mr-3" />
                <p>{{c.name}}</p>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full mt-2" floatLabel="always">
          <mat-label>Mobile Number</mat-label>
          <p matTextPrefix class="text-on-surface mx-1" *ngIf="selectedCountry">
            +{{selectedCountry.numeric_code}}</p>
          <input required matInput formControlName="mobile" class="  " [maxlength]="15">
          <mat-hint *ngIf="!isUser" class="text-error">New here? Sign up
            today.</mat-hint>
          <mat-hint *ngIf="googleError" class="text-error">Failed to signin. Try
            again</mat-hint>
          <mat-hint *ngIf="userError" class="text-error">{{userError}}</mat-hint>
          <mat-error>
            <ng-container *ngIf="
            mobileForm.controls['mobile'].errors &&
            (mobileForm.controls['mobile'].dirty || mobileForm.controls['mobile'].touched)
          ">
                <ng-container *ngIf="(mobileForm.controls['mobile'].errors | keyvalue)[0] as error">
                    <ng-container *ngIf="error.key">
                        <span>{{ error.value['message'] }}</span>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-error>
        </mat-form-field>
      </form>
      <button (click)="onChangeLoginType()" class="text-primary mat-label-large self-start">Use Email
        Instead</button>
    </div>
  </div>

  <div class="flex flex-col gap-6">
    <div class="flex flex-col gap-5 justify-center">
        <ng-container *ngIf="!saveFormLoading; else loading">
            <button mat-flat-button #blueButton
                (click)="isValid()?save():showError()" [id]="'proceedButton'">Proceed</button>
        </ng-container>
        <ng-container>
            <div class="flex items-center justify-center">
                <asl-google-signin-button type='standard' size='large' [width]="googleButtonWidth<400?googleButtonWidth:326" text="continue_with"
                    logo_alignment="center"></asl-google-signin-button>
            </div>
        </ng-container>
    </div>

    <p class="mat-body-medium text-on-surface text-center">Don't have an account? <a
            routerLink="../signup" class="mat-body-medium text-primary underline">Create account</a></p>
</div>


</div>



<ng-template #loading>
<button-loader [width]="googleButtonWidth?.toString()||'326'"></button-loader>
</ng-template>
<ng-template #loadingMobile>
  <div class="flex justify-center items-center container border-none ">
    <mat-spinner [diameter]="50" ></mat-spinner>
  </div>
</ng-template>