import { CommonModule, NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { EntitlementsService, SharedService } from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService, User } from '@org.quicko/ngx-core';
import { NgOtpInputModule } from 'ng-otp-input';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from "../../../common/button-loader/button-loader.component";
import { HeaderComponent } from "../../../common/header/header.component";
import { OtpInputComponent } from "../../../common/otp-input/otp-input.component";

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  standalone: true,
  imports: [MatIconModule, CommonModule, MatButtonModule, ReactiveFormsModule,MatInputModule, NgClass, NgIf, NgOtpInputModule,  ButtonLoaderComponent, OtpInputComponent, HeaderComponent]
})
export class VerifyOtpComponent implements OnInit {
  email: string | undefined;
  mobile: string | undefined;
  countryCode: string | undefined;
  otp: string | undefined;
  timeLeft = 29;
  interval: any;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  destroy$ = new Subject<void>();
  errorMessage: string | undefined;
  @ViewChild('blueButton', { read: ElementRef, static: false }) blueButton!: ElementRef; // Reference to mat-button
  blueButtonWidth:number=326
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store:Store,
    private actions$:Actions,
    private snackbarService:SnackbarService,
    private entitlementsService: EntitlementsService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    
  }

  ngOnInit() {
    this.resendOTP();

    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.mobile = params['mobile'];
      this.countryCode = params['countryCode'];
    });


    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }
  ngOnChanges(): void {
    this.blueButtonWidth = this.blueButton.nativeElement.getBoundingClientRect().width
  }
  ngAfterViewInit(): void {
    this.blueButtonWidth = this.blueButton.nativeElement.getBoundingClientRect().width
  }

  verify() {
    if (this.email && this.otp) {
      this.saveFormLoading = true;
      const user= new User();
      user.username=this.email
      this.store.dispatch(UserActions.VERIFY_RESET_PASSWORD_EMAIL({user,otp:this.otp}))
      this.actions$.pipe(ofType(UserActions.VERIFY_RESET_PASSWORD_EMAIL_SUCCESS),take(1)).subscribe(({data})=>{
          this.authService.setToken(data['access_token']);
          this.router.navigate(['set-password']);
          this.saveFormLoading = false;
      })
      this.actions$.pipe(ofType(UserActions.VERIFY_RESET_PASSWORD_EMAIL_FAILURE),take(1)).subscribe((err)=>{
        if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
                this.errorMessage = err.error.message;
              }
              else {
                this.router.navigate(['login']);
              }
              this.saveFormLoading = false;
      })

    } else if (this.otp && this.mobile && this.countryCode) {
      this.saveFormLoading = true;
      const user= new User()
      user.mobile=this.mobile,
      user.country_code=this.countryCode
      this.store.dispatch(UserActions.VERIFY_RESET_PASSWORD_MOBILE({user,otp:this.otp}))
      this.actions$.pipe(ofType(UserActions.VERIFY_RESET_PASSWORD_MOBILE_SUCCESS),take(1)).subscribe(({data})=>{
            this.authService.setToken(data['access_token']);
            this.router.navigate(['set-password']);
            this.saveFormLoading = false;
      })
      this.actions$.pipe(ofType(UserActions.VERIFY_RESET_PASSWORD_MOBILE_FAILURE),take(1)).subscribe((err)=>{
              if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
              this.errorMessage = err.error.message;
            }
            else {
              this.snackbarService.openSnackBar("Failed to verify OTP",null)
              // this.router.navigate(['login']);
            }
            this.saveFormLoading = false;
      })
    } else {
      this.errorMessage = 'Provide valid OTP';
    }
  }

  generateOtp() {
    if (this.email) {
      const user=new User()
      user.username=this.email 
      this.store.dispatch(UserActions.RESET_PASSWORD_EMAIL({ user}));
    } else if (this.mobile && this.countryCode) {
      const user= new User()
      user.mobile= this.mobile,
      user.country_code= this.countryCode,
      this.store.dispatch(
        UserActions.RESET_PASSWORD_MOBILE({
          user
        })
      );
    }
    this.actions$.pipe(ofType(UserActions.RESET_PASSWORD_EMAIL_FAILURE),take(1)).subscribe((err)=>{
       if(!err.error.message.includes("Too many OTP generation attempts")){
        this.snackbarService.openSnackBar("Failed to send OTP",null)
      }
    })
    this.actions$.pipe(ofType(UserActions.RESET_PASSWORD_MOBILE_FAILURE),take(1)).subscribe((err)=>{
       if(!err.error.message.includes("Too many OTP generation attempts")){
        this.snackbarService.openSnackBar("Failed to send OTP",null)
      }
    })
  }

  onOtpChange(otp: string) {
    this.errorMessage = undefined;
    if (otp.length === 6) {
      this.otp = otp;
    }
  }

  resendOTP() {
    this.generateOtp();
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 30;
      }
    }, 1000);
  }

  back() {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
