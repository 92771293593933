<ng-otp-input (onInputChange)="onOtpChange($event)" (keyup.enter)="onEnterPress()" [config]="{
  length: 6,
  allowNumbersOnly: true,
  inputStyles: {
    'font-size': '20px',
    'font-weight': '500',
    'background-color':'var(--sys-surface-container-lowest)',
    'color':'var(--sys-on-surface)',
    width: '46px',
    height: '56px',
    'border-color': isError ? 'var(--sys-error)' : 'var(--sys-outline-variant)'
  }
}" class="w-full">
</ng-otp-input>