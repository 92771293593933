import { NgClass } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { AffiliateService, SharedService } from '@com.quicko/accounts-ngx-core';
import { ReferenceDataActions } from '@com.quicko/accounts-ngx-redux-core';
import { CookieService, ThemeService } from '@com.quicko/ngx-core';
import { Store } from '@ngrx/store';
import { ENV_CONFIG } from '@org.quicko/ngx-core';
import { Subject } from 'rxjs';
import { EmailLoginComponent } from './components/login/email-login/email-login.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, EmailLoginComponent,
    MatButtonModule, RouterModule, NgClass],
  templateUrl: './app.component.html',
})
export class AppComponent {
  isDarkEnable: boolean = false;
    destroy$ = new Subject<void>();

  constructor(private sessionCookieService: CookieService,
    private store:Store,
     private themeService:ThemeService, @Inject(ENV_CONFIG) private environment:any,
    private affiliateService: AffiliateService, private sharedService: SharedService, private activatedRoute: ActivatedRoute, private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.registerSprites();
  }

  registerSprites(){
    this.iconRegistry.addSvgIcon(
      'ic_logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.ic_quicko_logo)
    );
    this.iconRegistry.addSvgIcon(
      'ic_quicko_word_logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.ic_quicko_word_logo)
    );
    this.iconRegistry.addSvgIcon(
      'ic_google_logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.ic_google_logo)
    );

    // product logos
    this.iconRegistry.addSvgIcon(
      'ic_quicko_logo_circle',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.environment.ic_quicko_logo_circle
      )
    );
    this.iconRegistry.addSvgIcon(
      'ic_dark_quicko_logo_circle',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        this.environment.ic_dark_quicko_logo_circle
      )
    );
    this.iconRegistry.addSvgIcon(
      'ic_meet_logo_circle',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.ic_meet_logo_circle)
    );

    // social icons
    this.iconRegistry.addSvgIcon(
      'ic_twitter',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.ic_twitter)
    );
    this.iconRegistry.addSvgIcon(
      'ic_linkedin',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.ic_linkedin)
    );
    this.iconRegistry.addSvgIcon(
      'ic_instagram',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.environment.ic_instagram)
    );
  }

  ngOnInit() {
    this.store.dispatch(ReferenceDataActions.GET_COUNTRIES());
    this.store.dispatch(ReferenceDataActions.GET_APPS());
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
