<div
  class="flex flex-col pt-12 pb-8 rounded justify-between grow container border-none">

  <app-header [heading]="'Thank you for using Quicko'" [subHeading]="'We hope to see you again soon!'" [title]="email"></app-header>

  <div class="flex flex-col gap-8 justify-center">
    <div class="flex flex-col gap-4 justify-center px-4">
      <button mat-flat-button routerLink="../login" [id]="'logInAgainButton'">Log in
        again</button>
    </div>
    <div class="flex flex-col gap-4 border-t border-outline-variant pt-8">
      <p class="mat-body-large text-on-surface px-4 text-center">Enjoyed our products? Give us a shout-out
        on our social media handles!</p>

      <div class="flex flex-row gap-4 justify-center px-4">

        <div class="w-11 h-11 bg-none border-outline-variant rounded-full border items-center flex justify-center">
          <a href="https://x.com/Quicko_official" target="_blank" rel="nofollow">
            <mat-icon svgIcon="ic_twitter" class="w-[20px] h-[20px] flex justify-center"></mat-icon>
          </a>
        </div>

        <div class="w-11 h-11 bg-none border-outline-variant rounded-full border items-center flex justify-center">
          <a href="https://in.linkedin.com/company/quicko-official" target="_blank" rel="nofollow">
            <mat-icon svgIcon="ic_linkedin" class="w-[20px] h-[20px] flex justify-center"></mat-icon>
          </a>
        </div>

        <div class="w-11 h-11 bg-none border-outline-variant rounded-full border items-center flex justify-center">
          <a href="https://www.instagram.com/quicko_official/" target="_blank" rel="nofollow">
            <mat-icon svgIcon="ic_instagram" class="w-[20px] h-[20px] flex justify-center"></mat-icon>
          </a>
        </div>
      </div>
    </div>
  </div>

</div>