import {
  GoogleSigninButtonModule,
  SocialAuthService,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { ENV_CONFIG, SnackbarService, UserDetails } from '@org.quicko/ngx-core';
import {
  RxFormBuilder,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';

import {
  EntitlementsService,
  OAuthService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import { OAuthActions, UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';

@Component({
  selector: 'app-email-signup',
  templateUrl: './email-signup.component.html',
  standalone: true,
  imports: [
    MatFormField,
    CommonModule,
    MatLabel,
    RxReactiveFormsModule,
    MatButtonModule,
    ButtonLoaderComponent,
    ReactiveFormsModule,
    NgClass,
    MatHint,
    MatError,
    MatIcon,
    NgIf,
    SocialLoginModule,
    MatInputModule,
    RouterModule,
    NgStyle,
    GoogleSigninButtonModule,
    HeaderComponent,
  ],
})
export class EmailSignupComponent implements OnInit, OnChanges {
  isDarkEnable: boolean = false;
  userForm: FormGroup;
  isUser: boolean = false;
  googleError: boolean = false;
  redirectUrl: string | undefined;
  signUploading: boolean = false;
  googleLoading: boolean = false;
  userError: string | undefined;
  googleButtonWidth: number;

  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
destroy$ = new Subject<void>();
  constructor(
    private router: Router,
    private entitlementsService: EntitlementsService,
    private socialAuthService: SocialAuthService,
    private oauthService: OAuthService,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private store: Store,
    private authService: AuthService,
    @Inject(ENV_CONFIG) private environment: any,
    private activatedRoute: ActivatedRoute,
    private fb: RxFormBuilder,
    private sharedService: SharedService
  ) {
    this.googleButtonWidth = 326;

    this.userForm = this.fb.group({
      email: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Email is required' }),
          RxwebValidators.email({ message: 'Invalid email' }),
        ],
      ],
      firstName: [
        '',
        [RxwebValidators.notEmpty({ message: 'First name is required' })],
      ],
      lastName: [
        '',
        [RxwebValidators.notEmpty({ message: 'Last name is required' })],
      ],
    });

   
  }

  ngOnChanges(): void {
    this.googleButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.googleButtonWidth =
        this.blueButton.nativeElement.getBoundingClientRect().width;
    });
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params) => {
      this.userForm.controls['email'].setValue(params['email']);
      this.userForm.controls['firstName'].setValue(params['name']);
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.signUploading = true;
        this.store.dispatch(
          OAuthActions.AUTHORIZE_GOOGLE({
            oAuthToken: user['idToken'],
            email: user['email'],
            firstName: user['firstName'] ? user['firstName'] : null,
            lastName: user['lastName'] ? user['lastName'] : null,
          })
        );
      }
    });

    this.actions$
      .pipe(ofType(OAuthActions.AUTHORIZE_GOOGLE_SUCCESS))
      .subscribe(({data}) => {
        this.authService.setToken(data['access_token']);

        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        } else {
          window.location.href = this.environment.income_tax_url;
        }
        this.googleLoading = false;
        this.signUploading = true;
      });

    this.actions$
      .pipe(ofType(OAuthActions.AUTHORIZE_GOOGLE_FAILURE))
      .subscribe((err) => {
        if (err.error.message.includes('User is locked')) {
          this.router.navigate(['./locked']);
        }
        this.googleError = true;
        this.googleLoading = false;
      });

    this.userForm.controls['email'].valueChanges.subscribe(() => {
      this.isUser = false;
      this.googleError = false;
      this.userError = undefined;
    });
  }

  showError() {
    this.userForm.markAllAsTouched();
  }
  isValid() {
    return this.userForm.valid;
  }

  signUp() {
    this.signUploading = true;
    const userDetails= new UserDetails()
    userDetails.username=this.userForm.controls['email'].value
    userDetails.first_name=this.userForm.controls['firstName'].value
    userDetails.last_name=this.userForm.controls['lastName'].value
    this.store.dispatch(
      UserActions.SIGNUP({
        user:userDetails
      })
    );

    this.actions$
      .pipe(ofType(UserActions.SIGNUP_SUCCESS), take(1))
      .subscribe(() => {
        this.router.navigate(['verify'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            email: this.userForm.controls['email'].value,
            name: this.userForm.controls['firstName'].value,
          },
        });
        this.signUploading = false;
      });

    this.actions$
      .pipe(ofType(UserActions.SIGNUP_FAILURE), take(1))
      .subscribe((err) => {
        if (err.error.message.includes('User already exist')) {
          this.isUser = true;
        } else if (
          err.error.message.includes('User is locked') ||
          err.error.message.includes('User is inactive')
        ) {
          this.userError = err.error.message;
        } else if(err.error.message.includes("Too many OTP generation attempts")){
          this.router.navigate(['verify'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              email: this.userForm.controls['email'].value,
              name: this.userForm.controls['firstName'].value,
            },
          });
        }else {
          this.snackbarService.openSnackBar("Failed to Signup",null)
        }
        this.signUploading = false;
      });
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
