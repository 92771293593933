import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { ENV_CONFIG } from "@org.quicko/ngx-core";
import { Observable, catchError, map } from "rxjs";
import { EntitlementsService, SharedService } from "../services";
import { AuthService } from "@com.quicko/ngx-core";

@Injectable()
export class NotLoggedIn implements CanActivate {

  redirectUrl: string | undefined;

  constructor( @Inject(ENV_CONFIG) private environment:any,private authService: AuthService, private router: Router, private sharedService: SharedService, private entitlementsService: EntitlementsService,) {
    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isAuthenticated()) {
      if (this.redirectUrl) {
        window.location.href = window.atob(this.redirectUrl);
      }
      else {
        window.location.href = this.environment.income_tax_url;
      }
      return false;
    } else {

      if (this.authService.getToken()) {
        return this.entitlementsService.refresh(this.authService.getToken()).pipe(map((response) => {

          if (response.access_token)
            this.authService.setToken(response.access_token);

          if (this.redirectUrl) {
            window.location.href = window.atob(this.redirectUrl);
          }
          else {
            window.location.href = this.environment.income_tax_url;
          }
          return false;
        }),
          catchError(async () => {
            if (this.redirectUrl) {
              window.location.href = window.atob(this.redirectUrl);
            }
            else {
              window.location.href = this.environment.income_tax_url;
            }
            return true;
          }))
      } else {
        return true;
      }
    }
  }

}
