import {
  GoogleSigninButtonModule,
  SocialAuthService,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { CommonModule, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, take } from 'rxjs';

import {
  EntitlementsService,
  OAuthService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import { OAuthActions, UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENV_CONFIG, SnackbarService, User } from '@org.quicko/ngx-core';
import {
  RxFormBuilder,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';
@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatFormField,
    MatLabel,
    MatButtonModule,
    RxReactiveFormsModule,
    ReactiveFormsModule,
    MatHint,
    MatError,
    NgIf,
    SocialLoginModule,
    MatInputModule,
    RouterModule,
    ButtonLoaderComponent,
    GoogleSigninButtonModule,
    HeaderComponent,
  ],
})
export class EmailLoginComponent implements OnInit, OnChanges {
  userForm: FormGroup;
  isUser: boolean = true;
  googleError: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  googleLogin: boolean = false;
  userError: string | undefined;
  googleButtonWidth: number;
  destroy$ = new Subject<void>();
  user:any

  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    @Inject(ENV_CONFIG) private environment: any,
    private oauthService: OAuthService,
    private fb: RxFormBuilder,
    private store: Store,
    private actions$: Actions,
    private snackbarsService: SnackbarService,
    private sharedService: SharedService
  ) {
    this.googleButtonWidth = 326;

    this.userForm = this.fb.group({
      email: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Email is required' }),
          RxwebValidators.email({ message: 'Invalid email' }),
        ],
      ],
    });


  }

  ngOnChanges(): void {
    this.googleButtonWidth =
      this.blueButton?.nativeElement?.getBoundingClientRect().width;
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.googleButtonWidth =
        this.blueButton?.nativeElement?.getBoundingClientRect().width;
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userForm.controls['email'].setValue(params['email']);
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.saveFormLoading = true;
        this.store.dispatch(
          OAuthActions.AUTHORIZE_GOOGLE({
            oAuthToken: user['idToken'],
            email: user['email'],
            firstName: user['firstName'] ? user['firstName'] : null,
            lastName: user['lastName'] ? user['lastName'] : null,
          })
        );
      }
    });

    this.actions$
      .pipe(ofType(OAuthActions.AUTHORIZE_GOOGLE_SUCCESS))
      .subscribe(({data}) => {
        this.authService.setToken(data['access_token']);

        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        } 
        else {
          window.location.href = this.environment.income_tax_url;
        }
        this.googleLogin = false;
        this.saveFormLoading = false;
      });

    this.actions$.pipe(ofType(OAuthActions.AUTHORIZE_GOOGLE_FAILURE)).subscribe((err)=>{
      if (err.error.message.includes('User is locked')) {
        this.router.navigate(['./locked']);
      }
      this.saveFormLoading = false;
      this.googleError = true;
      this.googleLogin = false;
    })

    this.userForm.controls['email'].valueChanges.subscribe(() => {
      this.isUser = true;
      this.googleError = false;
      this.userError = undefined;
    });
  }

  showError() {
    this.userForm.markAllAsTouched();
  }
  isValid() {
    return this.userForm.valid;
  }
  save() {
    this.saveFormLoading = true;
    const user=new User();
    user.username=this.userForm.controls['email'].value
    this.store.dispatch(
      UserActions.GET_USER_METADATA({
        user,
      })
    );
    this.actions$
      .pipe(ofType(UserActions.GET_USER_METADATA_FAILURE))
      .subscribe((err) => {
        if (err.error.message.includes('User does not exist')) {
          this.isUser = false;
        } else if (
          err.error.message.includes('User is locked') ||
          err.error.message.includes('User is inactive')
        ) {
          if (err.error.message.includes('User is locked')) {
            this.router.navigate(['locked']);
          }
          this.userError = err.error.message;
        }
        this.saveFormLoading = false;
      });

    this.actions$
      .pipe(
        ofType(UserActions.GET_USER_METADATA_SUCCESS),
        take(1)
      )
      .subscribe(({data}) => {
        this.user=data;
        
        this.router.navigate(['./challenge/otp'], {
          relativeTo: this.activatedRoute,
          queryParams: {
            email: this.userForm.controls['email'].value,
            credential: this.user['credential_status'],
          },
        });
        this.saveFormLoading = false;
      });
  }

  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
