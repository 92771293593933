<div id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">

    <app-header [heading]="'Set my password'" [subHeading]="'Keep your account secure with a unique password'"></app-header>

    <form [formGroup]="passwordForm" (keyup.enter)="isValid()?save():showError()" class="mt-8">
      <mat-form-field appearance="outline" class="h-14 w-full">
        <mat-label>New Password</mat-label>
        <input required matInput formControlName="newPassword" [type]="showPassword ? 'text' : 'password'" autocomplete="off">
        <mat-icon matSuffix class="material-icons-outlined" (click)="showPassword=true"
          *ngIf="!showPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-icons-outlined" (click)="showPassword=false"
          *ngIf="showPassword">visibility_off</mat-icon>
          <mat-error>
            <ng-container *ngIf="
            passwordForm.controls['newPassword'].errors &&
            (passwordForm.controls['newPassword'].dirty || passwordForm.controls['newPassword'].touched)
          ">
                <ng-container *ngFor="let error of passwordForm.controls['newPassword'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                        <span>{{ error.value['message'] }}</span>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-error>
      </mat-form-field>

      <div class="flex flex-col mt-6">
        <ng-container *ngFor="let key of objectKeys(validations); first as isFirst">
          <div class="flex flex-row items-center   text-outline mt-1" *ngIf="!isFirst">
            <mat-icon class="material-symbols-outlined text-[20px] size-5 flex items-center justify-center"
              [ngClass]="validations[key].bool ? 'text-primary' : 'text-outline'">check_circle</mat-icon>
            <p class="mat-body-medium text-on-surface ml-2 ">{{validations[key].message}}</p>
          </div> 
        </ng-container>
      </div>

      <mat-form-field appearance="outline" class="h-14 w-full mt-8">
        <mat-label>Confirm Password</mat-label>
        <input required matInput formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'"
          onCopy="return false" onCut="return false" onDrag="return false" onpaste="return false" ondrop="return false"
          autocomplete="off">
          <mat-error>
            <ng-container *ngIf="
            passwordForm.controls['confirmPassword'].errors &&
            (passwordForm.controls['confirmPassword'].dirty || passwordForm.controls['confirmPassword'].touched)
          ">
                <ng-container *ngFor="let error of passwordForm.controls['confirmPassword'].errors | keyvalue">
                    <ng-container *ngIf="error.key">
                        <span>{{ error.value['message'] }}</span>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-error>
        <mat-icon matSuffix class="material-icons-outlined" (click)="showConfirmPassword=true"
          *ngIf="!showConfirmPassword">visibility</mat-icon>
        <mat-icon matSuffix class="material-icons-outlined" (click)="showConfirmPassword=false"
          *ngIf="showConfirmPassword">visibility_off</mat-icon>
      </mat-form-field>
    </form>

    <ng-container *ngIf="!saveFormLoading; else loading">
      <button #blueButton class="mt-[36px]" mat-flat-button  (click)="isValid()?save():showError()" [id]="'savePasswordButton'">Save Password</button>
    </ng-container>

  </div>


  <ng-template #loading>
    <div class="mt-[36px]">
      <button-loader [width]="blueButtonWidth?.toString()||'326'"></button-loader>
    </div>
  </ng-template>
</div>