<section *ngIf="apps; else loading" id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 rounded justify-between grow container border-none">
    <div class="px-4">
      <div class="flex flex-col items-center gap-2">
        <div>
          <mat-icon *ngIf="(theme$|async)==='dark'" svgIcon="ic_dark_quicko_logo_circle"
            class="h-[50px] w-[50px] absolute"></mat-icon>
          <mat-icon *ngIf="(theme$|async)==='light'" svgIcon="ic_quicko_logo_circle" class="h-[50px] w-[50px] absolute"></mat-icon>
          <div
            class="h-[50px] w-[50px] relative ml-9 border-[3px] rounded-[50%] border-outline-variant  flex items-center justify-center">
            <img src="{{app['icon']}}" class="h-[40px] w-[40px] rounded-[50%]" />
          </div>
        </div>
        <div class=" text-on-surface mat-title-large ">Logging you into Quicko</div>
        <div class=" text-on-surface mat-body-large text-center ">We love our <span>{{app['name']}}</span>
          users!
        </div>
      </div>

      <p class="mat-title-medium text-on-surface   mt-10">Here’s what we access</p>

      <ul class="list-disc">
        <li class=" ml-4 text-outline mat-body-large  mt-4">
          Your basic profile details
        </li>
        <li class=" ml-4 text-outline mat-body-large  mt-4" >
          Your communication details
        </li>
        
      </ul>

      <p class="mat-title-medium text-on-surface   mt-8">Here’s what we do</p>
      <ul class="list-disc">
        <li class=" ml-4 text-outline mat-body-large  mt-4">
          Setting up your profile on Quicko
        </li>
        
      </ul>

    </div>
    <div class="mt-32">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      <p class=" mat-body-medium text-on-surface text-center mt-8">Please wait while we connect. Do not press
        back, refresh, or
        close this page</p>
    </div>
  </div>


</section>

<ng-template #loading>
  <div class="flex justify-center items-center ">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</ng-template>