import { CommonModule, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService, ReferenceDataService, SharedService } from '@com.quicko/accounts-ngx-core';
import { OAuthActions, ReferenceDataSelectors } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService, ThemeService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENV_CONFIG, SnackbarService } from '@org.quicko/ngx-core';
import { Subject, Subscription, takeUntil } from 'rxjs';

@Component({
  selector: 'app-oauth',
  templateUrl: './oauth.component.html',
  standalone: true,
  imports: [MatIconModule, NgIf, MatProgressBarModule, MatProgressSpinnerModule,CommonModule]
})
export class OAuthComponent implements OnInit {

  requestToken: string | null = '';
  queryParamSubscription: Subscription;
  isDarkEnable: boolean = false;
  appKey: string | null = '';
  redirectUrl: string | undefined;
  app: any;
  apps$=this.store.select(ReferenceDataSelectors.APPS);
  theme$=this.themeService.theme$;
  apps: any;
  destroy$=new Subject<void>()

  constructor(
    private activatedRoute: ActivatedRoute,
    private oAuthService: OAuthService,
    private authService: AuthService,
    private referenceDataService: ReferenceDataService,
    private router: Router,
    private store:Store,
    private actions$:Actions,
    private snackbarService:SnackbarService,
    private themeService:ThemeService,
    @Inject(ENV_CONFIG) private environment:any,
    private sharedService: SharedService,
  ) {

    

    
  }

  ngOnInit() {

    this.queryParamSubscription = this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('request_token') != null) {
        this.requestToken = queryParams.get('request_token');
      }
      else {
        this.router.navigate(['login']);
      }
    })

    this.activatedRoute.paramMap.subscribe(paramMap => {
      if (paramMap.get('app_key') != null) {
        this.appKey = paramMap.get('app_key');
      }
      else {
        this.router.navigate(['login']);
      }
    })

    if (this.appKey == "com-indiainfoline") {
      this.requestToken = window.btoa(this.requestToken as string);
    }

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });

    this.apps$.pipe(takeUntil(this.destroy$)).subscribe((data)=>{
      if(data){
        this.apps = data;
        this.app = this.apps.find(
          (a: any) => a.app_key === this.appKey
        );
        if (!this.app) {
          this.router.navigate(['login']);
        }
      }

    })

    this.store.dispatch(OAuthActions.AUTHORIZE_BROKER({requestToken:this.requestToken,apiKey:this.appKey}))
    this.actions$.pipe(ofType(OAuthActions.AUTHORIZE_BROKER_SUCCESS),takeUntil(this.destroy$)).subscribe(({data})=>{
      this.authService.setToken(data['access_token'])

        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        }
        else {
          window.location.href = this.environment.income_tax_url;
        }
    })
    this.actions$.pipe(ofType(OAuthActions.AUTHORIZE_BROKER_FAILURE),takeUntil(this.destroy$)).subscribe((error)=>{
      this.snackbarService.openSnackBar("Failed to Authorize broker",null);
      this.router.navigate(['/login']);
    })
  }

  ngOnDestory() {
    this.queryParamSubscription.unsubscribe();
  }


    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
