<div id="email-login" class="flex flex-col items-center">
    <div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">

        <app-header [heading]="'Set my password'" [title]="'Please provide your registered email'"></app-header>

        <form [formGroup]="userForm" (keyup.enter)="save()" class="mt-8">
            <mat-form-field class="w-full" appearance="outline">
                <mat-label >Email</mat-label>
                <input required matInput formControlName="email" placeholder="Email"
                    class="  ">
                <mat-hint *ngIf="!isUser" class="  text-error">This account does not
                    exist</mat-hint>
                    <mat-error>
                        <ng-container *ngIf="
                        userForm.controls['email'].errors &&
                        (userForm.controls['email'].dirty || userForm.controls['email'].touched)
                      ">
                            <ng-container *ngIf="(userForm.controls['email'].errors | keyvalue)[0] as error">
                                <ng-container *ngIf="error.key">
                                    <span>{{ error.value['message'] }}</span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </mat-error>
            </mat-form-field>
        </form>

        <button (click)="onChangeLoginType()" class="mat-body-medium text-primary mt-3 mb-6 self-start">Use Phone Number
            Instead</button>

        <div class="flex flex-col gap-4 justify-center mt-[188px]">
            <ng-container *ngIf="!saveFormLoading; else loading">
                <button mat-flat-button #blueButtonWidth
                    (click)="save()" [id]="'proceedButton'">Proceed</button>
            </ng-container>
        </div>

        <p class=" text-on-surface mat-body-medium  mt-6 text-center">Don't have an account? <a
                routerLink="/signup" class="text-primary underline">Create account</a></p>

    </div>


    <ng-template #loading>
    <button-loader [width]="blueButtonWidth?.toString()||'326'"></button-loader>
    </ng-template>
</div>