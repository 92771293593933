import { createAction, props } from '@ngrx/store';
import { v4 as uuidv4 } from 'uuid';

export const ReferenceDataActions = {
    GET_COUNTRIES: createAction('[COUNTRIES] GET Countries ' + uuidv4()),
    GET_COUNTRIES_SUCCESS: createAction('[COUNTRIES] GET Countries Success ' + uuidv4(), props<{ countries: Array<any> }>()),
    GET_COUNTRIES_FAILED: createAction('[COUNTRIES] GET Countries Failed ' + uuidv4(), props<{ message: string }>()),
    
    GET_APPS: createAction('[APPS] GET Apps ' + uuidv4()),
    GET_APPS_SUCCESS: createAction('[APPS] GET Apps Success ' + uuidv4(), props<{ apps: Array<any> }>()),
    GET_APPS_FAILED: createAction('[APPS] GET Apps Failed ' + uuidv4(), props<{ message: string }>()),
}