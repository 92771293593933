import { Routes } from '@angular/router';
import { LoggedIn, NotLoggedIn } from '@com.quicko/accounts-ngx-core';
import { LockedComponent } from './components/locked/locked.component';
import { AccountRecoveryComponent } from './components/login/account-recovery/account-recovery.component';
import { VerifyOtpComponent } from './components/login/account-recovery/verify-otp/verify-otp.component';
import { LoginComponent } from './components/login/login.component';
import { OtpLoginComponent } from './components/login/otp-login/otp-login.component';
import { PasswordLoginComponent } from './components/login/password-login/password-login.component';
import { SetPasswordComponent } from './components/login/reset-password/set-password.component';
import { LogoutComponent } from './components/logout/logout.component';
import { OAuthComponent } from './components/oauth/oauth.component';
import { ReAuthenticateComponent } from './components/reauthenticate/reauthenticate.component';
import { EmailSignupComponent } from './components/signup/email-signup/email-signup.component';
import { LinkMobileComponent } from './components/signup/link-mobile/link-mobile.component';
import { VerifyEmailComponent } from './components/signup/verify-email/verify-email.component';
import { VerifyMobileComponent } from './components/signup/verify-mobile/verify-mobile.component';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },

    {
        path: 'login', canActivate: [NotLoggedIn],
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'challenge',
                children: [
                    {
                        path: '',
                        redirectTo: 'login',
                        pathMatch: 'full'
                    },
                    {
                        path: 'password',
                        component: PasswordLoginComponent,
                    },
                    {
                        path: 'otp',
                        component: OtpLoginComponent,
                    },
                ],
            },
        ],
    },
    {
        path: 'signup', canActivate: [NotLoggedIn],
        children: [
            {
                path: '',
                component: EmailSignupComponent,
            },
            {
                path: 'verify',
                component: VerifyEmailComponent,
            },
        ],
    },
    {
        path: 'link-mobile', 
        canActivate: [LoggedIn],
        children: [
            {
                path: '',
                component: LinkMobileComponent,
            },
            {
                path: 'verify',
                component: VerifyMobileComponent,
            },
        ],
    },
    {
        path: 'set-password',
        children: [
            {
                path: '', 
                canActivate: [LoggedIn],
                component: SetPasswordComponent,
            },
            {
                path: 'verify', canActivate: [NotLoggedIn],
                children: [
                    {
                        path: '',
                        component: AccountRecoveryComponent,
                    },
                    {
                        path: 'otp',
                        component: VerifyOtpComponent,
                    }
                ],
            },
        ]
    },
    {
        path: 'oauth/apps/:app_key/authorize', 
        canActivate: [NotLoggedIn],
        component: OAuthComponent
    },
    {
        path: 'logout', 
        canActivate: [LoggedIn], 
        component: LogoutComponent
    },
    {
        path: 'reauthenticate', component: ReAuthenticateComponent
    },
    {
        path: 'locked', component: LockedComponent
    },
    {
        path: '**', redirectTo: 'login'
    }
];

