<div *ngIf="selectedCountry; else loadingMobile" id="email-login" class="flex flex-col items-center">
	<div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">

		<app-header [heading]="'Hey there , '+name+'!'" [subHeading]="'For additional security , please add & verify your phone number'"></app-header>

		<form class="flex flex-col mt-8" [formGroup]="mobileForm" (keyup.enter)="isValid()?save():showError()">
			<mat-form-field appearance="outline" class="w-full">
				<img matPrefix src="{{selectedCountry.flag}}" class="h-5 w-7 ml-4 mr-3" />
				<mat-select [(value)]="selectedCountry" [ngClass]="{'dark':isDarkEnable}">
					<mat-option *ngFor="let c of countryList" [value]="c">
						<div class="flex flex-row">
							<img src="{{c.flag}}" class="h-5 w-7 mr-3" />
							<p>{{c.name}}</p>
						</div>
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" class="w-full" floatLabel="always">
				<mat-label >Mobile Number</mat-label>
				<p matTextPrefix class=" text-on-surface mat-body-large  mx-1" *ngIf="selectedCountry">
					+{{selectedCountry.numeric_code}}</p>
				<input required matInput formControlName="mobile" class="  " [maxlength]="15">
				<mat-hint *ngIf="isUser" class="  text-error">Mobile already linked to
					another account</mat-hint>
					<mat-error>
						<ng-container *ngIf="
						mobileForm.controls['mobile'].errors &&
						(mobileForm.controls['mobile'].dirty || mobileForm.controls['mobile'].touched)
					  ">
							<ng-container *ngIf="(mobileForm.controls['mobile'].errors | keyvalue)[0] as error">
								<ng-container *ngIf="error.key">
									<span>{{ error.value['message'] }}</span>
								</ng-container>
							</ng-container>
						</ng-container>
					</mat-error>
			</mat-form-field>
		</form>

		<div class="flex flex-col gap-4 justify-center mt-[176px]">
			<ng-container *ngIf="!saveFormLoading; else loading">
				<button mat-flat-button #blueButton
					(click)="isValid()?save():showError()" [id]="'continueButton'">Continue</button>
			</ng-container>
			<button mat-stroked-button
				(click)="skip()" [id]="'skipButton'">Skip</button>
		</div>

	</div>



	<ng-template #loading>
		<button-loader [width]="blueButtonWidth?.toString()||'326'"></button-loader>
	</ng-template>
</div>

<ng-template #loadingMobile>
	<div class="flex justify-center items-center container border-none ">
		<mat-spinner [diameter]="50"></mat-spinner>
	</div>
</ng-template>