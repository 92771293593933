import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { EntitlementsService } from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService, User } from '@org.quicko/ngx-core';
import {
  RxFormBuilder,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from '../../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../../common/header/header.component';

@Component({
  selector: 'app-email-recovery',
  templateUrl: './email-recovery.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatFormField,
    MatInputModule,

    NgFor,
    NgIf,
    RouterModule,
  
    ButtonLoaderComponent,
    HeaderComponent,
  ],
})
export class EmailRecoveryComponent implements OnInit {
  userForm: FormGroup;
  isUser: boolean = true;
  saveFormLoading: boolean = false;
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  blueButtonWidth:number
  @Output() changeLoginTypeEmitter: EventEmitter<boolean> = new EventEmitter();
  destroy$ = new Subject<void>();
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private fb: RxFormBuilder,
    private entitlementsService: EntitlementsService
  ) {
    this.userForm = this.fb.group({
      email: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Email is required' }),
          RxwebValidators.email({ message: 'Invalid email' }),
        ],
      ],
    });

  }

  ngOnInit() {
    this.userForm.controls['email'].valueChanges.subscribe(() => {
      this.isUser = true;
    });
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userForm.controls['email'].setValue(params['email']);
    });
  }

  save() {
    if (this.userForm.valid) {
      this.saveFormLoading = true;
      const user=new User()
      user.username=this.userForm.controls['email'].value
      this.store.dispatch(
        UserActions.GET_USER_METADATA({
          user
        })
      );
      this.actions$
        .pipe(ofType(UserActions.GET_USER_METADATA_SUCCESS),take(1))
        .subscribe(() => {
      
          this.saveFormLoading = false;
          this.router.navigate(['otp'], {
            relativeTo: this.activatedRoute,
            queryParams: {
              email: this.userForm.controls['email'].value,
            },
          });
        });
      this.actions$
        .pipe(ofType(UserActions.GET_USER_METADATA_FAILURE), take(1))
        .subscribe((err) => {
          if (err.error.message.includes('User does not exist')) {
            this.isUser = false;
          }
          this.saveFormLoading = false;
        });

    
    } else {
      this.userForm.controls['email'].markAsTouched();
    }
  }
  ngOnChanges(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.blueButtonWidth =
        this.blueButton.nativeElement.getBoundingClientRect().width;
    });
  }

  onChangeLoginType() {
    this.changeLoginTypeEmitter.emit(true);
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
