import { Injectable } from '@angular/core';
import { SharedService } from '@com.quicko/accounts-ngx-core';
import { CookieService, ThemeService } from '@com.quicko/ngx-core';

@Injectable({
    providedIn: 'root',
})
export class InitializerService {

    constructor(
        private cookieService: CookieService,
        private sharedService:SharedService,
        private themeService: ThemeService
    ) { }


    private setRedirectQueryParameter(){
        const queryParams = new URL(window.location.href).search;

        const params = new URLSearchParams(queryParams);

        const redirect = params.get('redirect');

        this.sharedService.setRedirectUrl(redirect);
    }

    private setTrackingCookies() {
        const queryParams = new URL(window.location.href).search;

        const params = new URLSearchParams(queryParams);

        const affiliateId = params.get('affiliate_id');

        if (affiliateId && !this.cookieService.getCookie('AFFILIATEID')) {
            this.cookieService.setCookie('AFFILIATEID', affiliateId, 30);
        }

        if (params.get('utm_source')) {
            this.cookieService.setCookie("UTM_SOURCE", params.get('utm_source')!);
        }

        if (params.get('utm_medium')) {
            this.cookieService.setCookie("UTM_MEDIUM", params.get('utm_medium')!);
        }

        if (params.get('utm_campaign')) {
            this.cookieService.setCookie("UTM_CAMPAIGN", params.get('utm_campaign')!);
        }

        if (params.get('utm_content')) {
            this.cookieService.setCookie("UTM_CONTENT", params.get('utm_content')!);
        }

        if (params.get('utm_id')) {
            this.cookieService.setCookie("UTM_ID", params.get('utm_id')!);
        }

        if (params.get('utm_term')) {
            this.cookieService.setCookie("UTM_TERM", params.get('utm_term')!);
        }
    }

    

    run() {

        return () => new Promise((resolve) => {

            //set up tracking cookies
            this.setTrackingCookies();
            
            //set up redirect parameters
            this.setRedirectQueryParameter();

            //set global theme
            this.themeService.initializeTheme();
            
            resolve(null);
        })
    }
}
