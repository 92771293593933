<div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">

    <div class="flex flex-col gap-8">
        <app-header [heading]="'Login into Quicko'" [subHeading]="'Welcome back!'"></app-header>
        <div class="flex flex-col gap-3">
            <form [formGroup]="userForm" (keyup.enter)="isValid()?save():showError()">
                <mat-form-field class="w-full" appearance="outline">
                    <mat-label>Email</mat-label>
                    <input required matInput formControlName="email" placeholder="Email">
                    <mat-hint *ngIf="!isUser" class="text-error">New here? Sign up
                        today.</mat-hint>
                    <mat-hint *ngIf="googleError" class="text-error">Failed to signin. Try
                        again</mat-hint>
                    <mat-hint *ngIf="userError"
                        class="text-error">{{userError}}</mat-hint>
                        <mat-error>
                            <ng-container *ngIf="
                            userForm.controls['email'].errors &&
                            (userForm.controls['email'].dirty || userForm.controls['email'].touched)
                          ">
                                <ng-container *ngIf="(userForm.controls['email'].errors | keyvalue)[0] as error">
                                    <ng-container *ngIf="error.key">
                                        <span>{{ error.value['message'] }}</span>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </mat-error>
                </mat-form-field>
            </form>

            <button (click)="onChangeLoginType()" class="mat-label-large text-primary self-start">Use Phone
                Number
                Instead</button>
        </div>
    </div>

    <div class="flex flex-col gap-6">
        <div class="flex flex-col gap-5 justify-center">
            <ng-container *ngIf="!saveFormLoading; else loading">
                <button mat-flat-button #blueButton
                    (click)="isValid()?save():showError()" [id]="'proceedButton'">Proceed</button>
            </ng-container>
            <ng-container>
                <div class="flex items-center justify-center">
                    <asl-google-signin-button type='standard' size='large' [width]="googleButtonWidth<400?googleButtonWidth:326" text="continue_with"
                        logo_alignment="center"></asl-google-signin-button>
                </div>
            </ng-container>
        </div>

        <p class="mat-body-medium text-on-surface text-center">Don't have an account? <a
                routerLink="../signup" class="mat-body-medium text-primary underline">Create account</a></p>
    </div>

</div>

<ng-template #loading>
 <button-loader [width]="googleButtonWidth?.toString()||'326'"></button-loader>
</ng-template>