import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { EntitlementsService, SharedService } from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SnackbarService } from '@org.quicko/ngx-core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-reauthenticate',
  templateUrl: './reauthenticate.component.html',
})
export class ReAuthenticateComponent implements OnInit {

  redirectUrl: string | undefined;
destroy$ = new Subject<void>();
  constructor(private entitlementsService: EntitlementsService, private store:Store,private actions$:Actions,
    private authService: AuthService, private sharedService: SharedService, private router: Router, private activatedRoute: ActivatedRoute,private snackbarService:SnackbarService) { }

  ngOnInit() {
    this.store.dispatch(UserActions.LOGOUT());
   

    
    this.authService.deleteToken();

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });

    this.router.navigate(['login'], {
      queryParams: {
        redirect: this.redirectUrl
      },
    });
  }


    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
