<div id="email-login" class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">

    <app-header [heading]="'Set my password'" [subHeading]="'Please enter the OTP sent to '" [title]="email?email: '+'+ countryCode+' '+mobile "></app-header>
    <div class="mt-8 flex flex-col items-center justify-center">
      <app-otp-input  [isError]="errorMessage?true:false" (onInputChange)="onOtpChange($event)" (onEnter)="verify()"></app-otp-input>
    </div>
    <p [ngClass]="errorMessage ? 'mb-2' : 'mb-6'" class="mat-label-large mt-1 text-error text-center ml-4">{{errorMessage}}
    </p>

    <button (click)="resendOTP()" [disabled]="timeLeft != 30"
      [ngClass]="timeLeft == 30 ? 'text-primary' : 'text-outline'"
      class="mat-label-large flex justify-start self-start h-[72px] mb-4">Resend
      OTP<span *ngIf="timeLeft != 30"> (Available in {{timeLeft}})</span></button>

    <div class="flex flex-col gap-4 justify-center mt-24">
      <ng-container *ngIf="!saveFormLoading; else loading">
        <button mat-flat-button #blueButton
          (click)="verify()" [id]="'verifyButton'">Verify</button>
      </ng-container>
      <button mat-stroked-button
        (click)="back()" [id]="'backButton'">Back</button>
    </div>

  </div>


  <ng-template #loading>
<button-loader [width]="blueButtonWidth?.toString()||'326'"></button-loader>
  </ng-template>
</div>