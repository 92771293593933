import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  standalone:true,
  imports:[CommonModule,NgOtpInputModule]
})
export class OtpInputComponent implements OnInit {
destroy$ = new Subject<void>();
  @Output() onInputChange:EventEmitter<any>=new EventEmitter()
  @Output() onEnter:EventEmitter<void>=new EventEmitter()
  @Input() isError:boolean
  constructor() { }

  ngOnInit() {
  }
  onOtpChange($event){
    this.onInputChange.emit($event);
  }
  onEnterPress(){
    this.onEnter.emit();
  }


    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
