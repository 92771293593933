import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.css'],
  standalone: true,
  imports: [NgIf, NgStyle],
})
export class ButtonLoaderComponent implements OnInit {

  @Input() width?: string;
  @Input() height?: string;
  @Input() color?: 'primary' | 'secondary' | 'tertiary' | 'error' | 'warn' | 'success' | 'elite' | 'meet-blue' | 'meet-red';
  @Input() type?: 'primary' | 'secondary';
  destroy$ = new Subject<void>();
  btnWidth!: string;
  btnHeight!: string;
  btnColor!: string;

  constructor() { }

  ngOnInit(): void {
    this.color = this.color ?? "primary";
    this.btnWidth = this.width ?? '112';
    this.btnHeight = this.height ?? '44';
    this.btnColor = this.color ?? 'var(--sys-primary)';
    this.type = this.type ?? 'primary';
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
