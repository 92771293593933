import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule, NgClass, NgIf, NgStyle } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatError,
  MatFormField,
  MatHint,
  MatLabel,
} from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import {
  EntitlementsService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENV_CONFIG, SnackbarService, User } from '@org.quicko/ngx-core';
import { NgOtpInputModule } from 'ng-otp-input';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';
import { OtpInputComponent } from '../../common/otp-input/otp-input.component';

@Component({
  selector: 'app-verify-mobile',
  templateUrl: './verify-mobile.component.html',
  standalone: true,
  imports: [
    MatFormField,
    MatButtonModule,
    CommonModule,
    MatLabel,
    ReactiveFormsModule,
    NgClass,
    MatHint,
    MatError,
    MatIcon,
    NgIf,
    SocialLoginModule,
    MatInputModule,
    NgOtpInputModule,
    NgStyle,
    ButtonLoaderComponent,
    OtpInputComponent,
    HeaderComponent,
  ],
})
export class VerifyMobileComponent implements OnInit {
  email!: string;
  name!: string;
  mobile!: string;
  countryCode!: string;
  otp: string | undefined;
  timeLeft = 29;
  destroy$ = new Subject<void>();
  interval: any;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  errorMessage: string | undefined;
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  blueButtonWidth:number
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private entitlementsService: EntitlementsService,
    @Inject(ENV_CONFIG) private environment: any,
    private sharedService: SharedService
  ) {
   
  }


  ngOnInit() {

    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });

    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.name = params['name'];
      this.mobile = params['mobile'];
      this.countryCode = params['countryCode'];
      if (!this.mobile || !this.countryCode) {
        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        } else {
          window.location.href = this.environment.income_tax_url;
        }
      }
    });

    this.interval = this.createInterval();
  }
  createInterval(){
    return setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 30;
      }
    }, 1000);
  }
  ngOnChanges(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.blueButtonWidth =
        this.blueButton.nativeElement.getBoundingClientRect().width;
    });
  }

  verify() {
    this.saveFormLoading = true;
      const user=new User()
      user.username= this.email,
      user.mobile= this.mobile,
      user.country_code= this.countryCode,
    this.store.dispatch(
      UserActions.SIGNUP_LINK_MOBILE_VERIFY({
        user,
        otp: this.otp,
      })
    );
    this.actions$
      .pipe(ofType(UserActions.SIGNUP_LINK_MOBILE_VERIFY_SUCCESS), take(1))
      .subscribe(() => {
        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        } else {
          window.location.href = this.environment.income_tax_url;
        }
        this.saveFormLoading = false;
      });
    this.actions$
      .pipe(ofType(UserActions.SIGNUP_LINK_MOBILE_VERIFY_FAILURE), take(1))
      .subscribe((err) => {
      if (err.error.message.includes('Expired OTP') || err.error.message.includes('Invalid OTP')) {
            this.errorMessage = err.error.message;
          }
          else {
            // this.router.navigate(['signup']);
            this.snackbarService.openSnackBar("Failed to verify OTP",null)
          }
          this.saveFormLoading = false;
      });
  }

  isValid() {
    return this.otp?.length === 6;
  }
  showError() {
    this.errorMessage = 'Provide valid OTP';
  }

  generateOtp() {
    const user=new User()
    user.username=this.email,
    user.mobile= this.mobile,
    user.country_code=this.countryCode,
    this.store.dispatch(
      UserActions.SIGNUP_LINK_MOBILE({
       user
      })
    );
    this.actions$
      .pipe(ofType(UserActions.SIGNUP_LINK_MOBILE_FAILURE), take(1))
      .subscribe((err) => {
        if(!err.error.message.includes("Too many OTP generation attempts")){
        this.snackbarService.openSnackBar("Failed to send OTP",null)
      }
      });
  }

  onOtpChange(otp: string) {
    this.errorMessage = undefined;
    this.otp = otp;
  }

  resendOTP() {
    this.generateOtp();
    this.interval = this.createInterval();
  }

  back() {
    this.router.navigate(['../'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
        name: this.name,
      },
    });
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
