import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, tap } from "rxjs";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        return event;
      }
      return false;
    }, (err: any) => {

      if (err instanceof HttpErrorResponse) {
        if (err.status === 403) {
          if (!this.router.url.toString().includes("/login"))
            this.router.navigate(['login'], { queryParams: { redirectUrl: window.btoa(this.router.url) } });
          return event;
        }
      }
      return false;
    }));
  }
}