import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { CommonModule, NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';

import {
  EntitlementsService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { AuthService } from '@com.quicko/ngx-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENV_CONFIG, SnackbarService, User } from '@org.quicko/ngx-core';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';
import { OtpInputComponent } from '../../common/otp-input/otp-input.component';

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  standalone: true,
  imports: [
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    SocialLoginModule,
    MatInputModule,
    NgOtpInputModule,
    ButtonLoaderComponent,
    HeaderComponent,
    OtpInputComponent,
  ],
})
export class OtpLoginComponent implements OnInit {
  email: string | undefined;
  mobile: string | undefined;
  countryCode: string | undefined;
  credential: string | undefined;
  otp: string | undefined;
  timeLeft = 29;
  interval: any;
  isDarkEnable: boolean = false;
  redirectUrl: string | undefined;
  saveFormLoading: boolean = false;
  destroy$=new Subject<void>()
  errorMessage: string | undefined;
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  blueButtonWidth: number;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private entitlementsService: EntitlementsService,
    private authService: AuthService,
    private store: Store,
    private actions$: Actions,
    private snackbarService:SnackbarService,
    private sharedService: SharedService,
    @Inject(ENV_CONFIG) private environment: any
  ) {
    
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.mobile = params['mobile'];
      this.countryCode = params['countryCode'];
      this.credential = params['credential'];
      if (!this.email && (!this.mobile || !this.countryCode)) {
        this.router.navigate(['../']);
      }
    });

    this.sharedService.getRedirectUrl().subscribe((redirectUrl: any) => {
      this.redirectUrl = redirectUrl;
    });
    this.resendOTP();
    
  }

  ngOnChanges(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  verify() {
    if (this.email && this.otp) {
      this.saveFormLoading = true;
      const user =new User();
      user.username=this.email;
      
      this.store.dispatch(
        UserActions.VERIFY_EMAIL_OTP({ user, otp: this.otp })
      );

      this.actions$
        .pipe(ofType(UserActions.VERIFY_EMAIL_OTP_SUCCESS), take(1))
        .subscribe(({data}) => {
          this.authService.setToken(data['access_token']);
          if (this.redirectUrl) {
            window.location.href = window.atob(this.redirectUrl);
          } else {
            window.location.href = this.environment.income_tax_url;
          }
          this.saveFormLoading = false;
        });

      this.actions$
        .pipe(ofType(UserActions.VERIFY_EMAIL_OTP_FAILURE), take(1))
        .subscribe((err) => {
          if (
            err.error.message.includes('Expired OTP') ||
            err.error.message.includes('Invalid OTP')
          ) {
            this.errorMessage = 'Please enter a valid OTP';
          } else {
            this.router.navigate(['login']);
          }
          this.saveFormLoading = false;
        });
    } else if (this.otp && this.mobile && this.countryCode) {
      const user=new User;
          user.mobile= this.mobile
          user.country_code= this.countryCode,
      this.saveFormLoading = true;
      this.store.dispatch(
        UserActions.VERIFY_MOBILE_OTP({
          user,
          otp: this.otp,
        })
      );
      this.actions$
        .pipe(ofType(UserActions.VERIFY_MOBILE_OTP_SUCCESS), take(1))
        .subscribe(({data}) => {
          this.authService.setToken(data['access_token']);
          if (this.redirectUrl) {
            window.location.href = window.atob(this.redirectUrl);
          } else {
            window.location.href = this.environment.income_tax_url;
          }
          this.saveFormLoading = false;
        });

      this.actions$
        .pipe(ofType(UserActions.VERIFY_MOBILE_OTP_FAILURE), take(1))
        .subscribe((err) => {
          if (
            err.error.message.includes('Expired OTP') ||
            err.error.message.includes('Invalid OTP')
          ) {
            this.errorMessage = 'Please enter a valid OTP';
          } else {
            this.router.navigate(['login']);
          }
          this.saveFormLoading = false;
        });
    } else {
      this.errorMessage = 'Provide valid OTP';
    }
  }
  showError() {
    this.errorMessage = 'Provide valid OTP';
  }
  isValid() {
    return this.otp?.length === 6;
  }

  generateOtp() {
    if (this.email) {
      const user=new User();
      user.username=this.email
      this.store.dispatch(UserActions.GET_OTP_BY_EMAIL({ user }));
    } else if (this.mobile && this.countryCode) {
      const user=new User;
      user.mobile= this.mobile
      user.country_code= this.countryCode,
      this.store.dispatch(
        UserActions.GET_OTP_BY_MOBILE({
          user
        })
      );
    }
    this.actions$.pipe(ofType(UserActions.GET_OTP_BY_EMAIL_FAILURE),take(1)).subscribe((err)=>{
      if(!err.error.message.includes("Too many OTP generation attempts")){
        this.snackbarService.openSnackBar("Failed to send OTP",null)
      }
    })
    this.actions$.pipe(ofType(UserActions.GET_OTP_BY_MOBILE_FAILURE),take(1)).subscribe((err)=>{
      if(!err.error.message.includes("Too many OTP generation attempts")){
        this.snackbarService.openSnackBar("Failed to send OTP",null)
      }
    })
  }

  onOtpChange(otp: string) {
    this.errorMessage = undefined;
    this.otp = otp;
  }

  resendOTP() {
    this.generateOtp();
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.interval);
        this.timeLeft = 30;
      }
    }, 1000);
  }

  navigateToPassword() {
    this.router.navigate(['../password'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        credential: this.credential,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

  back() {
    this.router.navigate(['../../'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        email: this.email,
        mobile: this.mobile,
        countryCode: this.countryCode,
      },
    });
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
