import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Theme, ThemeService } from '@com.quicko/ngx-core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone:true,
  imports:[MatIconModule,CommonModule],
})
export class HeaderComponent implements OnInit {
@Input() heading?:string
@Input() subHeading?:string
@Input() title?:string
destroy$ = new Subject<void>();
themePreference:Theme
  constructor(private themeService:ThemeService) { }
  theme$=this.themeService.theme$
  ngOnInit() {
    this.theme$.subscribe((data)=>{
      if(data===Theme.SYSTEM){
        data=this.themeService.getSystemThemePreference();
      }
      this.themePreference=data
    })
  }


    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
