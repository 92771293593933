import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@com.quicko/ngx-core";
import { Observable } from "rxjs";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.getToken() != '' && this.auth.getToken() != null &&
      !request.url.includes('https://content.quicko.com')) {
      request = request.clone({
        setHeaders: {
          authorization: this.auth.getToken(),
        },
      });
    }
    return next.handle(request);
  }
}