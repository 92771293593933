import { Injectable } from '@angular/core';
import { OAuthService } from '@com.quicko/accounts-ngx-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OAuthActions } from './oauth.actions';

@Injectable()
export class OAuthEffects {
  constructor(private actions$: Actions, private oauthService: OAuthService) {}

  // Authorize Google OAuth
  authorizeGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OAuthActions.AUTHORIZE_GOOGLE),
      switchMap((action) =>
        this.oauthService.authorizeGoogle(action.oAuthToken, action.email, action.firstName, action.lastName).pipe(
          map(response => OAuthActions.AUTHORIZE_GOOGLE_SUCCESS({ data: response })),
          catchError(async err => OAuthActions.AUTHORIZE_GOOGLE_FAILURE({ error: err }))
        )
      )
    )
  );

  // Authorize Broker OAuth
  authorizeBroker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OAuthActions.AUTHORIZE_BROKER),
      switchMap((action) =>
        this.oauthService.authorizeBroker(action.requestToken, action.apiKey).pipe(
          map(response => OAuthActions.AUTHORIZE_BROKER_SUCCESS({ data: response })),
          catchError(async err => OAuthActions.AUTHORIZE_BROKER_FAILURE({ error: err }))
        )
      )
    )
  );
}
