<div class="flex flex-col items-center">

  <div class="flex flex-col pt-12 rounded justify-between grow container border-none">
    <div class="flex justify-center items-center px-4">
      <app-header [heading]="'Account Locked'" [subHeading]="'This account is locked due to multiple failed login attempts'"></app-header>
    </div>
    <div class="py-6 bg-surface-container w-full">
      <p class="mat-body-large text-on-surface text-center ">Please retry in few minutes</p>
    </div>

  </div>

</div>