import { CommonModule, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { Subject } from 'rxjs';
import { HeaderComponent } from "../common/header/header.component";

@Component({
  selector: 'app-locked',
  templateUrl: './locked.component.html',
  standalone: true,
  imports: [MatIconModule, MatDividerModule, NgIf, RouterModule, CommonModule, HeaderComponent]
})
export class LockedComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
destroy$ = new Subject<void>();



    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
