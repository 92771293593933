import { CommonModule, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';

import {
  EntitlementsService,
  SharedService,
} from '@com.quicko/accounts-ngx-core';
import { UserActions } from '@com.quicko/accounts-ngx-redux-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ENV_CONFIG, SnackbarService, User } from '@org.quicko/ngx-core';
import {
  RxFormBuilder,
  RxReactiveFormsModule,
  RxwebValidators,
} from '@rxweb/reactive-form-validators';
import { Subject, take } from 'rxjs';
import { ButtonLoaderComponent } from '../../common/button-loader/button-loader.component';
import { HeaderComponent } from '../../common/header/header.component';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  standalone: true,
  imports: [
    MatIconModule,
    RxReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormField,
    MatInputModule,
    NgClass,
    NgFor,
    NgIf,
    ButtonLoaderComponent,
    HeaderComponent,
  ],
})
export class SetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  validations: any;
  isDarkEnable: boolean = false;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  redirectUrl: string | undefined;
  destroy$ = new Subject<void>();
  saveFormLoading: boolean = false;
  blueButtonWidth:number
  @ViewChild('blueButton', { read: ElementRef, static: false })
  blueButton!: ElementRef; // Reference to mat-button
  constructor(
    private router: Router,
    private entitlementService: EntitlementsService,
    private store: Store,
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private sharedService: SharedService,
    private fb: RxFormBuilder,
    @Inject(ENV_CONFIG) private environment: any
  ) {
    this.passwordForm = this.fb.group({
      newPassword: [
        '',
        [
          RxwebValidators.notEmpty({ message: 'Password is required' }),
          RxwebValidators.pattern({
            expression: {
              password:
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
            },
            message: 'Invalid password',
          }),
        ],
      ],
      confirmPassword: [
        '',
        [
          RxwebValidators.compare({
            fieldName: 'newPassword',
            message: 'Passwords must match',
          }),
        ],
      ],
    });

    this.validations = {
      samePassword: {
        bool: false,
        message: 'Passwords do not match',
      },
      minLength: {
        bool: false,
        message: 'At least 8 characters long',
      },
      hasLowercase: {
        bool: false,
        message: 'Contain at least one lowercase character',
      },
      hasUppercase: {
        bool: false,
        message: 'Contain at least one uppercase character',
      },
      hasNumber: {
        bool: false,
        message: 'Contain at least one number',
      },
      hasSpecialChar: {
        bool: false,
        message: 'Contain at least one special character',
      },
    };

   
  }

  ngOnInit() {
    this.sharedService.getRedirectUrl().subscribe((redirectUrl) => {
      this.redirectUrl = redirectUrl;
    });
    this.passwordForm.valueChanges.subscribe(() => {
      this.checkValidations();
    });
  }
  ngOnChanges(): void {
    this.blueButtonWidth =
      this.blueButton.nativeElement.getBoundingClientRect().width;
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.blueButtonWidth =
        this.blueButton.nativeElement.getBoundingClientRect().width;
    });
  }

  save() {
    this.saveFormLoading = true;
    const user=new User()
    user.password=this.passwordForm.controls['newPassword'].value
    this.store.dispatch(
      UserActions.SET_PASSWORD({
        user
      })
    );
    this.actions$
      .pipe(ofType(UserActions.SET_PASSWORD_SUCCESS), take(1))
      .subscribe(() => {
        if (this.redirectUrl) {
          window.location.href = window.atob(this.redirectUrl);
        } else {
          window.location.href = this.environment.income_tax_url;
        }
        this.saveFormLoading = false;
      });
    this.actions$
      .pipe(ofType(UserActions.SET_PASSWORD_FAILURE), take(1))
      .subscribe((error) => {
        this.saveFormLoading = false;
        this.snackbarService.openSnackBar('Failed to set password', null);
      });
  }

  showError() {
    this.passwordForm.markAllAsTouched();
  }
  isValid() {
    return this.passwordForm.valid;
  }

  checkValidations() {
    const newPassword = this.passwordForm.controls['newPassword'].value;
    // check all validations
    let allValidations: boolean = true;

    // check length
    if (newPassword.length >= 8) {
      this.validations['minLength'].bool = true;
    } else {
      this.validations['minLength'].bool = false;
      allValidations = false;
    }

    // check lowercase
    if (/[a-z]/.test(newPassword)) {
      this.validations['hasLowercase'].bool = true;
    } else {
      this.validations['hasLowercase'].bool = false;
      allValidations = false;
    }

    // check uppercase
    if (/[A-Z]/.test(newPassword)) {
      this.validations['hasUppercase'].bool = true;
    } else {
      this.validations['hasUppercase'].bool = false;
      allValidations = false;
    }

    // check number
    if (/\d/.test(newPassword)) {
      this.validations['hasNumber'].bool = true;
    } else {
      this.validations['hasNumber'].bool = false;
      allValidations = false;
    }

    // check special character
    if (/[!@#$%^&*]/.test(newPassword)) {
      this.validations['hasSpecialChar'].bool = true;
    } else {
      this.validations['hasSpecialChar'].bool = false;
      allValidations = false;
    }
    return allValidations;
  }

  objectKeys(obj: any) {
    return Object.keys(obj);
  }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
