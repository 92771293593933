import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, User, UserDetails } from '@org.quicko/ngx-core';
import { AffiliateService } from './affiliate.service';


@Injectable({
  providedIn: 'root',
})
export class EntitlementsService {
  private endpoint;

  constructor( @Inject(ENV_CONFIG) private environment:any,
    private httpClient: HttpClient,
    private affiliateService: AffiliateService
  ) {
    this.endpoint = environment.auth_services_endpoint + '/auth/user';
  }

  getUserMetadata(user:User) {
    const url = this.endpoint + '/meta';
   
    return this.httpClient.post(url, user);
  }

  getUserMetadataByMobile(user:User) {
    const url = this.endpoint + '/meta';
    
    return this.httpClient.post(url, user);
  }

  refresh(token: string) {
    const url = this.endpoint + '/authorize';
    return this.httpClient.post<any>(url, null, {
      params: new HttpParams().set('request_token', token)
    });
  }

  login(user:User) {
    const url = this.endpoint + '/login';
    return this.httpClient.post(url, user);
  }

  logout() {
    const url = this.endpoint + '/logout';
    return this.httpClient.post(url, null);
  }

  loginByMobile(user:User) {
    const url = this.endpoint + '/login';
    return this.httpClient.post(url, user);
  }

  getOTPByEmail(user:User) {
    const url = this.endpoint + '/login/otp/generate';
   
    return this.httpClient.post(url, user);
  }

  verifyEmailOTP(user:User,otp: string) {
    const url = this.endpoint + '/login/otp/verify';
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  getOTPByMobile(user:User) {
    const url = this.endpoint + '/login/otp/generate';
    
    return this.httpClient.post(url, user);
  }

  verifyMobileOTP(user:User, otp: string) {
    const url = this.endpoint + '/login/otp/verify';
   
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  getOTPByNewMobile(user:User) {
    const url = this.endpoint + '/mobile';
   
    return this.httpClient.post(url, user);
  }

  verifyNewMobileOTP(
    user:User,
    otp: string
  ) {
    const url = this.endpoint + '/mobile/otp/verify';
    
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  signup(user:UserDetails) {
  
    const url = this.endpoint + '/signup';
    return this.httpClient.post(url, user);
  }

  signupOTPVerify(user:User, otp: string) {
    let params: HttpParams = new HttpParams().set('otp', otp);
    
    params = this.affiliateService.getAffiliateParams(params);

    const url = this.endpoint + '/signup/otp/verify';
    return this.httpClient.post(url, user, {
      params: params,
    });
  }

  setPassword(user:User) {
   
    const url = this.endpoint + '/password';
    return this.httpClient.post(url, user);
  }

  resetPasswordEmail(user: User) {
    const url = this.endpoint + '/reset-password';
    return this.httpClient.post(url, user);
  }

  resetPasswordMobile(user:User) {
  
    const url = this.endpoint + '/reset-password';
    return this.httpClient.post(url, user);
  }

  verifyResetPasswordEmail(user:User,otp: string) {
    const url = this.endpoint + '/reset-password/otp/verify';
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }

  verifyResetPasswordMobile(user:User,otp: string) {
    const url = this.endpoint + '/reset-password/otp/verify';
    return this.httpClient.post(url, user, {
      params: new HttpParams().set('otp', otp),
    });
  }
}
