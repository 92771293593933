@if(type === 'primary') {
  <div [ngStyle]="{ width: btnWidth + 'px', height: btnHeight + 'px', backgroundColor: 'var(--sys-' + color + ')' }"
    class="flex items-center justify-center rounded-[5px]">
    <div class="loader" [ngStyle]="{ '--loader-color': 'var(--sys-on-' + color + ')' }"></div>
  </div>
  }
  
  @if(type === 'secondary') {
  <div [ngStyle]="{ width: btnWidth + 'px', height: btnHeight + 'px', backgroundColor: 'transparent' }"
    class="border border-outline-variant flex items-center justify-center rounded-[5px]">
    <div class="loader" [ngStyle]="{ '--loader-color': 'var(--sys-' + color + ')' }"></div>
  </div>
  }
  