<div class="flex flex-col items-center">
  <div class="flex flex-col pt-12 pb-8 px-4 rounded justify-between grow container border-none">
  
    <div class="flex flex-col gap-8 ">
      <app-header [heading]="'Sign up at Quicko'" [subHeading]="'Join 2 Million happy taxpayers!'"></app-header>
      <div class="flex flex-col">
        <form [formGroup]="userForm" class="flex flex-col gap-2" (keyup.enter)="isValid()?signUp():showError()">
          <mat-form-field class="w-full" appearance="outline">
            <mat-label>Email</mat-label>
            <input required matInput formControlName="email" placeholder="Email" class="  ">
            <mat-hint *ngIf="isUser" class="text-error">Account already
              registered.</mat-hint>
            <mat-hint *ngIf="googleError" class="text-error">Failed to signin. Try
              again</mat-hint>
            <mat-hint *ngIf="userError" class="text-error">{{userError}}</mat-hint>
            <mat-error>
              <ng-container *ngIf="
              userForm.controls['email'].errors &&
              (userForm.controls['email'].dirty || userForm.controls['email'].touched)
            ">
                  <ng-container *ngIf="(userForm.controls['email'].errors | keyvalue)[0] as error">
                      <ng-container *ngIf="error.key">
                          <span>{{ error.value['message'] }}</span>
                      </ng-container>
                  </ng-container>
              </ng-container>
          </mat-error>
          </mat-form-field>
  
          <mat-form-field class="w-full" appearance="outline">
            <mat-label>First Name</mat-label>
            <input required matInput formControlName="firstName" placeholder="First Name" class=" ">
            <mat-error>
              <ng-container *ngIf="
              userForm.controls['firstName'].errors &&
              (userForm.controls['firstName'].dirty || userForm.controls['firstName'].touched)
            ">
                  <ng-container *ngIf="(userForm.controls['firstName'].errors | keyvalue)[0] as error">
                      <ng-container *ngIf="error.key">
                          <span>{{ error.value['message'] }}</span>
                      </ng-container>
                  </ng-container>
              </ng-container>
          </mat-error>
          </mat-form-field>
  
          <mat-form-field class="w-full" appearance="outline">
            <mat-label>Last Name</mat-label>
            <input required matInput formControlName="lastName" placeholder="Last Name"
              class="  ">
              <mat-error>
                <ng-container *ngIf="
                userForm.controls['lastName'].errors &&
                (userForm.controls['lastName'].dirty || userForm.controls['lastName'].touched)
              ">
                    <ng-container *ngIf="(userForm.controls['lastName'].errors | keyvalue)[0] as error">
                        <ng-container *ngIf="error.key">
                            <span>{{ error.value['message'] }}</span>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-error>
          </mat-form-field>
        </form>
       
      </div>
    </div>
    <div class="flex flex-col gap-6">
      <div class="mt-2 flex flex-col">
        <div class="flex flex-col gap-5 justify-center">
          <ng-container  *ngIf="!signUploading; else loading">
            <button #blueButton mat-flat-button (click)="isValid()?signUp():showError()" [id]="'signUpButton'">
              Sign up
            </button>
          </ng-container>
          <ng-container *ngIf="!googleLoading; else blueLoading">
            <div class="flex items-center justify-center">
              <asl-google-signin-button type='standard' size='large' [width]="googleButtonWidth<400?googleButtonWidth:326" text="continue_with"
                logo_alignment="center"></asl-google-signin-button>
            </div>
          </ng-container>
        </div>

        <p class="mt-6 text-center mat-body-medium text-outline">By signing up, you agree to our <a
            href="https://help.quicko.com/portal/en/kb/articles/terms-conditions-of-use"
            target="_blank" class="underline">Terms of Service.</a></p>
      </div>
      <p class=" mat-body-medium text-on-surface text-center">Already have an account? <a
          routerLink="../login" class="mat-body-medium text-primary underline">Sign
          in</a></p>

    </div>
  
  </div>

</div>



<ng-template #loading>
  <button-loader [type]="'primary'"  [width]="googleButtonWidth?.toString()||'326'"></button-loader>
</ng-template>
<ng-template #blueLoading>
  <button-loader [type]="'secondary'"  [width]="googleButtonWidth?.toString()||'326'"></button-loader>
</ng-template>